import { gql } from '@apollo/client';

export const FLOORED_CARS = gql`
  query {
    currentDealer {
      name
      flooredCars(scopes: ["unpaid"])
       {
        id

        dealerLine { id, name, dealer { id, name } }
        market { id, name }
        color
        termData {
          totalDays
        }
        titleStatus
        vin
        year
        make
        model
        series
        style
        mileage
        loanAmount
        loanDate
        bosDate
        soldOn
        sourceType
        sourceId
        unitNumber
        unitNumber
        currentFees
        currentInterest
        currentPrincipal
        currentCollateralCoverage
        currentInsurance
        maxPayoffOn
        currentDueOn
        repoOn
        repoLocation
      }
    }
  }
`;
