import React from 'react';
import { Typography } from '../../styles';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import NewQuote from './new';
import { DEALER_FLOORED_CARS } from '../../queries/viewQueries';
import DataState from '../DataState';
import { Helmet } from 'react-helmet';
import { currency } from '../../utils/stringHelpers';
import { DateHelpers } from '../../utils';


const index = () => {
  const { loading, error, data } = useQuery(DEALER_FLOORED_CARS)

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;
  let unfiltered = data['currentDealer']['flooredCars'];

  //remove cars with scheduled_achs from payment list
  let flooredCars = [];
  unfiltered.forEach((car) => {
    if(car.scheduledAchs == 0) {
      flooredCars.push(car);
    }
  });

  // const { currentDealer: { flooredCars }} = data;

  const hrStyle = {
    width: '100%',
  }

  let total = 0;

  const payableFees = data.currentDealer.payableFees.map((fee) => {
    total += parseFloat(fee.due)
    return <>{fee.name}: {currency(fee.due)} - {DateHelpers.setDate(fee.chargeOn)}<br /></>
  })

  return (
    <>
      <Helmet>
        <title>{`Set Up Payment For ${data.currentDealer.name}`}</title>
      </Helmet>
      <div style={Typography.pageTitle}>Set Up Payment</div>
        {(() =>
          {if (data.currentDealer.payableFees.length > 0) {
            return (
              <>
                <hr style={hrStyle} />
                  <div style={{paddingLeft: "20px"}}>
                    <h4>The following Dealer Fee(s) will be added to this payment:</h4>
                    <div>{payableFees}</div>
                    <h5>total: {currency(total)}</h5>
                  </div>
                <hr style={hrStyle} />
              </>
            )
          }
        })()}
      <NewQuote data={flooredCars} total={total} />
    </>
  );
};

export default index;
