import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Home from '../components/Home';
import Cars from '../components/Cars/index';
import ShowCar from '../components/Cars/show';
import Logout from '../components/Logout';
import Notifications from '../components/Notifications';
import ChangePassword from '../components/Profile/ChangePassword';
import EditProfile from '../components/Profile/Edit';
import Payments from '../components/Payments';
import UpdateMobile from '../components/Profile/UpdateMobile';
import Receipts from '../components/Receipts';
import Report from '../components/Reporting/Show';
import WatermarkedTitlePdf from '../components/TitleLogs/show';

const Routes = () => {
  return (
    <>
      <Helmet titleTemplate="XL Funding | %s" defaultTitle="XL Funding">
        <meta charSet="utf-8" />
      </Helmet>
      <Switch>
        <Route path="/" component={Cars} exact />
        <Route path="/cars" component={Cars} exact />
        <Route path="/cars/:carId" component={ShowCar} exact />
        <Route path="/logout" component={Logout} />
        <Route path="/notifications" component={Notifications} exact />
        <Route path="/profile/change-password" component={ChangePassword} />
        <Route path="/profile/edit" component={EditProfile} />
        <Route path="/profile/update-mobile" component={UpdateMobile} />
        <Route path="/payments" component={Payments} exact />
        <Route path="/receipts" component={Receipts} exact />
        <Route path="/report/:type/:startOn/:endOn" component={Report} exact />
        <Route path="/report/:type/:id" component={Report} exact />
        <Route
          path="/cars/:carId/title-log"
          component={WatermarkedTitlePdf}
          exact
        />
      </Switch>
    </>
  );
}

export default Routes;
