import { gql } from '@apollo/client';

export const FLOORED_CAR = gql`
  query FlooredCar($id: ID!) {
    flooredCar(id: $id) {
      id
      year
      make
      model
      loanDate
      bosDate
      termData { term, totalDays, day }
      maxPayoffOn
      sourceType
      sourceId
      loanAmount
      currentPrincipal
      currentDueOn
      externalPaymentMethod
      advanceType
      currentFees
      currentInterest
      currentCollateralCoverage
      advanceTo
      repoLocation
      titleStatus
      color
      scheduledAchs
      scheduledAchAmount
      scheduledAchCarAmount
      scheduledAchType
      scheduledAchDate
      fees { name, amount, chargeOn, termNumber, termType, paid }
      titleLogs {
        id,
        trackingNumber
        titleStatus {
          name
        }
        createdAt,
        watermarkedFileUpload {
          id
          fileUrl
          name
        }
      }
      canCurtail
    }
  }
`
export const NOTIFICATIONS = gql`
  query($ownerId: Int!, $ownerType: String) {
    notifications(ownerId: $ownerId, ownerType: $ownerType) {
      id
      notification
      link
      ownerId
      ownerType
      createdAt
    }
  }
`

export const DEALER_FLOORED_CARS = gql`
  query CurrentDealer {
    currentDealer {
      id
      name
      payableFees {
        id
        amount
        name
        due
      }
      flooredCars(scopes: ["unpaid"]) {
        id
        unitNumber
        vin
        year
        make
        model
        loanDate
        currentDueOn
        termData { totalDays }
        currentPrincipal
        currentFees
        currentInterest
        currentCollateralCoverage
        arbitratedOn
        scheduledAchs
        canCurtail
        dealer {
          id
          payableFees {
            id
            amount
            name
            due
          }
          unpaidFees {
            id
            amount
            name
            due
          }
        }
      }
    }
  }
`

export const QUOTE_AMOUNTS = gql`
  query QuoteAmounts(
    $flooredCarId: Int!,
    $date: ISO8601Date,
    $paymentType: String
  ) {
    quoteAmounts(
      flooredCarId: $flooredCarId
      date: $date
      paymentType: $paymentType
    ) {
      principal, interest, insurance, reserves, fees
      feeDetails { due, id, name}
    }
  }
`

export const DEALER = gql`
  query CurrentDealer {
    currentDealer {
      id
      name
      accountNumber
      creditLine
      availableCredit
      currentPrincipal
      onAccountBalance
      reserveBalance
      autopay
      tempPrograms(scopes: ["active"]) {
        id
        dealerLine {
          id
          dealer {
            id
          }
        }
        programName
        programType
        effectiveOn
        endOn
        creditLimitIncrease
        createdBy {
          id
          firstName
          lastName
        }
      }
      payableFees {
        id
        name
        amount
        due
      }
      unpaidFees { id, name, amount, due }
      accountRep {
        id
        firstName
        lastName
        email
        mobilePhone {
          formatted
          value
        }
      }
      serviceRep {
        id
        firstName
        lastName
      }
      branch {
        id
        name
        code
      }
      market {
        id
        name
      }
      dealerStatus {
        id
        name
      }
      dealerLinesAttributes {
        rateProgram {
          name
          id
        }
        dealerProgram {
          id
          name
        }
      }
    }
  }
`;

export const DEALER_QUOTES = gql`
  query CurrentDealer {
    currentDealer {
      id
      name
      accountNumber
      quotes(scopes: ["receipts"]) {
        id
        bankDate
        paymentAmount
        txns { id, type, overageForReceipt }
        quoteFees { fee { name, amount } }
        quoteCars {
          principal, interest, reserves, insurance,
          quoteFees { fee { name, amount } }
          flooredCar { vin, year, make, model, color }
        }
      }
    }
  }
`;

export const TITLE_LOG = gql`
  query TitleLog($id: ID!) {
    titleLog(id: $id) {
      id
      trackingNumber
      titleStatus { name }
      notes
      fileUpload { id, fileUrl, name }
      watermarkedFileUpload { id, fileUrl, name }
    }
    currentUser { id }
  }
`
