import * as Colors from './colors';

export const boldText = {
  fontWeight: 'bold',
}

export const titleSize = {
  fontSize: '2em',
}

export const pageTitle = {
  ...boldText,
  ...titleSize,
  color: Colors.xlOrange,
  marginTop: '1%',
  paddingLeft: '0.7%'
}

export const formHeader = {
  ...pageTitle,
  marginTop: '0%',
  paddingLeft: '0%'
}

export const formSubHeader = {
  ...formHeader,
  fontSize: '18px'
}
