// https://benjaminbrooke.me/posts/react-form-validation-with-graphql-formik-and-yup/

export const normalizeErrors = errors => {
  return errors.reduce((accumulator, value) => {
    if ('attributes' === value.path[0]) {
      accumulator[value.path[1]] = value.message;
    } else {
      accumulator[value.path] = value.message;
    }

    return accumulator;
  }, {});
};

export const formatError = error => {
  return error && error[0].toUpperCase() + error.slice(1)
};
