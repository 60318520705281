import React from 'react';
import { useParams, Link } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { useLazyQuery } from '@apollo/client';
import * as DateHelper from '../../utils/dateHelpers'
import * as StringHelper from '../../utils/stringHelpers';
import {
  dataLabelStyle,
  dataValueStyle,
  carImageStyle,
  noCarImageStyle,
  rowStyle,
  greenRowStyle,
  linkStyle
} from './style'

export const labels = [
  'Loan Date',
  'Bill Of Sale Date',
  'Days on Floor Plan',
  'Current Term',
  'Days on Current Term',
  'Payoff Required On',
  'Source',
  'Funding Method',
  'Amount of Loan',
  'Current Principal Balance',
  'Next Payment Date',
  'Estimated Payment Due',
  'Title',
  'Most Recent Audit',
  'Audit Status'
  ]

export const setValues = (car) => {
  const { currentFees, currentInterest, currentCollateralCoverage } = car;
  const loanDate = DateHelper.setDate(car.loanDate);
  const bosDate = DateHelper.setDate(car.bosDate);
  const floorPlanDays = car.termData.totalDays || '';
  const term = car.termData.term || '';
  const daysOnCurrentTerm = car.termData.day || '';
  const payoffOn = DateHelper.setDate(car.maxPayoffOn) || '';
  const sourceType = formatSourceType(car.sourceType)
  const source = (car.sourceName)
    ? <a
        style={linkStyle}
        href={`/${sourceType}/${car.sourceId}`}
      >
        {car.sourceName}
      </a>
    : '';
  const fundingMethod = car.advanceTo || '';
  const totalDue = (
    parseFloat(currentFees) + parseFloat(currentInterest) + parseFloat(
      car.currentPrincipal) + parseFloat(currentCollateralCoverage)
  )
  const loanAmount = StringHelper.currency(car.loanAmount) || '';
  const currentPrincipal = StringHelper.currency(car.currentPrincipal) || '';
  const nextPaymentDate = DateHelper.setDate(car.currentDueOn) || '';

  let title = <a href={`${car.id}/title-log`}>view</a>;

  return [
    loanDate,
    bosDate,
    floorPlanDays,
    term,
    daysOnCurrentTerm,
    payoffOn,
    source,
    fundingMethod,
    loanAmount,
    currentPrincipal,
    nextPaymentDate,
    StringHelper.currency(totalDue),
    title,
    '',
    ''
  ]
}

const setRowStyle = (index) => {
  return (index % 2 === 0) ? rowStyle : greenRowStyle;
}

export const InfoTable = ({ labels, values }) => {
  if (!labels || !values) return null;

  const row = labels.map((label, index) => {
    return (
      <tr style={setRowStyle(index)} key={index}>
        <td style={dataLabelStyle}>{`${label}:`}</td>
        <td style={dataValueStyle}>{values[index]}</td>
      </tr>
    )
  })

  return (
    <Table responsive borderless>
      <tbody>
        {row}
      </tbody>
    </Table>
  )
}

export const Image = ({ src }) => {
  if (!src) return <h3 style={noCarImageStyle}>
    No Vehicle Image Available
  </h3>

  return <img
    style={carImageStyle}
    src='car.jpg'
    />
}

export const getItems = (items, start, finish=null) => {
    if (!finish) finish = items.length
    return items.slice(start, finish)
  }

const formatSourceType = (sourceType) => {
  sourceType = sourceType.match(/[A-Z][a-z]+/g)
  sourceType = sourceType.map((word) => word.toLowerCase())
  sourceType[sourceType.length - 1] += 's'
  return sourceType.join('-')
}
