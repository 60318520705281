import React, { useState } from 'react';
import logo from '../components/images/logo.png';
import { Formik, Form } from 'formik';
import * as BasicForm from '../elements/forms';
import { AUTH_TOKEN } from '../constants';
import secrets from '../secrets';
import * as Yup from 'yup';
import * as Buttons from '../elements/buttons';
import * as Colors from '../styles/colors';
import ForgotPassword from './ForgotPassword'
import { CustomAlert } from '../elements/BasicAlert';
import { Helmet } from 'react-helmet';

export const loginWrapper = {
  width: '100%',
  maxWidth: '330px',
  margin: 'auto',
  padding: '15px',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

export const signInText = {
  marginTop: '6%',
  marginBottom: '6%',
  fontSize: '1.71em',
  textAlign: 'center'
};

const Login: () => React$Node = () => {
  const [showModal, toggleShowModal] = useState(false)
  const [errorAlert, setErrorAlert] = useState(null);
  const [showCodeSentInputs, setShowCodeSentInputs] = useState(false)
  const [showJustPasswordInput, setShowJustPasswordInput] = useState(false)

  const handleSubmit = (values) => {
    const email = values.email;
    const password = values.password;
    const code = values.code;

    // set the body variable
    let body = JSON.stringify({ dealer_user: { email } });

    if (showJustPasswordInput) {
      body = JSON.stringify({ dealer_user: { email, password } })
    }

    if (showCodeSentInputs) {
      body = JSON.stringify({ dealer_user: { email, password, code } })
    }

    const options = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'JWT_AUD': secrets.api.jwtAud
      },
      body: body
    };

    let path = '/passwords/phone_lookup'
    if (showJustPasswordInput || showCodeSentInputs) path = '/dealer_users/sign_in'

    fetch(secrets.api.baseUri + path, options)
      .then(response => {
        if (!response.ok) {
          alert('Login failed, please retry');
        } else {
          if (showJustPasswordInput || showCodeSentInputs) {
            localStorage.setItem(AUTH_TOKEN, response.headers.get('authorization'));
            window.location.assign('/');
          } else {
            response.json().then(data => {
              const twilioStatus = data.twilio_status;
              if (twilioStatus === 'blacklisted') {
                setErrorAlert(
                  'The phone number given has opted out of receiving messages from XL Funding - if this was in error, please text START to (317-751-2161)'
                )
                return
              } else if (twilioStatus === 'no-response') {
                setErrorAlert(
                  'The provided phone number was unable to receive texts - please check that it is correct, and is a mobile number capable of receiving SMS messages.'
                )
              }
              data.code_sent === true ? setShowCodeSentInputs(true) : setShowJustPasswordInput(true)
            })
          }
        }

        // return response.json();
      });
  };

  const passwordLink = {
    color: Colors.xlGreen,
    textDecoration: 'none'
  }

  const handleForgottenPassword = () => {
    toggleShowModal(!showModal)
  }

  if (showModal) {
    return <ForgotPassword />
  }
  return (
    <>
      <Helmet>
        <title>XL Funding</title>
      </Helmet>
      {errorAlert && <CustomAlert message={errorAlert} />}
      <div style={loginWrapper}>
        <img src={logo} alt="Logo" height="72" width="72" style={{ margin: '0 auto' }} />
        <h3 style={signInText}>Please Sign In</h3>
        <Formik
          initialValues={{
            email: '',
            password: '',
            code: '',
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email('Invalid email address.')
              .required('Required'),
          })}
          onSubmit={handleSubmit}
        >
          <Form>
            <BasicForm.EmailInput
              name="email"
              type="email"
              placeholder="Email Address"
            />
            {showCodeSentInputs
              ? //
              <>
                <BasicForm.TextInput
                  name="password"
                  type="password"
                  label="Password"
                />
                <BasicForm.TextInput
                  name="code"
                  type="text"
                  label="Verification Code (sent via text)"
                />
              </>
              : null
            }
            {showJustPasswordInput
              ?
              <BasicForm.TextInput
                name="password"
                type="password"
                placeHolder="Password"
                label="Password"
              />
              : null
            }
            <Buttons.SignIn type="submit" text="Sign In" />
            <Buttons.Link
              text="Forgot Password?"
              onClick={handleForgottenPassword}
              style={passwordLink}
            />
          </Form>
        </Formik>
      </div >
    </>
  );
}

export default Login;
