import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Text } from 'react-native';
import { Helmet } from 'react-helmet';
import { useParams, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { PayForm } from './Form';
import { Spacing, Typography } from '../../styles';
import { formatValues } from './Functions';
import NewPayment from './ProcessPayment/Modal';
import DataState from '../DataState';

export const DEALER = gql`
  query CurrentDealer {
    currentDealer {
      id
      name
      reserveBalance
    }
  }
`;

const CREATE_QUOTE = gql`
  mutation CreateQuote($dealerId: Int!, $paymentDate: ISO8601Date!, $quoteCarsAttributes: [QuoteCarInput!], $quoteFeesAttributes: [QuoteFeeInput!]) {
    createQuote(
      dealerId: $dealerId
      paymentDate: $paymentDate
      quoteCarsAttributes: $quoteCarsAttributes
      quoteFeesAttributes: $quoteFeesAttributes
    ) {
      quote {
        id
        amount
        quoteFees { fee { id }, amount }
        quoteCars {
          id
          flooredCar { id }
          principal, interest, insurance
          quoteFees { fee { id }, amount }
        }
      }
      errors { path, message }
    }
}`;

const NewQuote = ({ data, total }) => {
  const [redirect, setRedirect] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [quoteId, setQuoteId] = useState(null);
  const [quoteAmount, setQuoteAmount] = useState(null);
  const { loading, error, data: dealerData }= useQuery(DEALER);

  let orderedData = useRef(data);
  const setOrderedData = (orderedData) => orderedData.current = orderedData;

   let persistedQuoteValues = useRef({});
  let quoteDate = useRef(null);

  const [createQuote, { loading: mutationLoading, error: mutationError }] = useMutation(CREATE_QUOTE, {
    onCompleted(values) {
      setQuoteId(parseInt(values.createQuote.quote.id));
      setQuoteAmount(parseFloat(values.createQuote.quote.amount));
      setShowModal(true);
    }
  })

  let today = new Date();
  today = today.toISOString().split('T')[0];

  if (loading || mutationLoading) return <DataState.Loading />
  if (error || mutationError) return <DataState.Error error={error || mutationError} />;

  const { currentDealer: { name, reserveBalance } } = dealerData;

  const handleSubmit = (values) => {
    persistedQuoteValues.current = values;
    quoteDate.current = values.paymentDate;

    delete values.maxPrincipal;

    formatValues(values.quoteCarsAttributes, [
      'principal',
      'paymentType',
      'feeDetails',
    ])

    createQuote({ variables: values })
  };

  const NewForm = () => {
    if (redirect) {
      return <Redirect
        to={{
          pathname: "/system/dealer-statuses",
          state: { alert: true, type: 'created' }
        }}
      />
    }

    const { paymentDate, quoteCarsAttributes, quoteFeesAttributes } = persistedQuoteValues.current;

    return (
      <Fragment>
        <div style={Object.assign({}, Spacing.formWrapper, {marginTop: '0'})}>
          <Formik
            initialValues={{
              dealerId: parseInt(dealerData.currentDealer.id),
              paymentDate: paymentDate || today,
              quoteCarsAttributes: quoteCarsAttributes || [],
              quoteFeesAttributes: quoteFeesAttributes || [],
              maxPrincipal: [],
            }}
            validationSchema={null}
            onSubmit={handleSubmit}
          >
            <PayForm
              data={orderedData.current}
              total={total}
              setOrderedData={setOrderedData}
              handleSubmit={handleSubmit}
            />
          </Formik>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div style={Spacing.formContainer}>
      {
        showModal && <NewPayment
          showModal={showModal}
          setShowModal={setShowModal}
          dealerName={name}
          quoteId={quoteId}
          reserveBalance={reserveBalance}
          quoteAmount={quoteAmount}
          quoteDate={quoteDate.current}
        />
      }
        <NewForm />
      </div>
    </Fragment>
  );
};

export default NewQuote;
