export default {
  api: {
    baseUri: process.env.REACT_APP_BASE_URI,
    jwtAud: 'react'
  },
  elasticSearch: {
    host: process.env.REACT_APP_ES_HOST,
    region: process.env.REACT_APP_ES_REGION,
    accessKey: process.env.REACT_APP_ES_ACCESS_KEY,
    secretKey: process.env.REACT_APP_ES_SECRET_KEY,
    username: process.env.REACT_APP_ES_USERNAME,
    password: process.env.REACT_APP_ES_PASSWORD
  }
}
