import moment from 'moment'

/*
    This function is used to set dates in indices in a way that makes Moment and
    Datatables.net play nice with each other when sorting columns.
*/
export const renderDate = (data, type) => {
  let newDate = new Date(data);
  let date = type === 'sort' || type === 'type'
    ? newDate.getTime()
    : data

  return date;
}


/*
  This function is to be used as a quick way to display common dates within
  indices, shows and other views, while rendering blank instead of "Invalid Date".
*/
export const setDate = (data) => {
  const date = moment(data).format('MM/DD/Y')

  if (date === 'Invalid date') return null;

  return date;
}

export const setISODate = (data) => {
  const date = moment(data).format('Y-MM-DD')

  if (date === 'Invalid date') return null;

  return date;
}

export const getDayOfWeek = (number) => {
  switch (number) {
    case 0:
      return 'Sunday'
    case 1:
      return 'Monday'
    case 2:
      return 'Tuesday'
    case 3:
      return 'Wednesday'
    case 4:
      return 'Thursday'
    case 5:
      return 'Friday'
    case 6:
      return 'Saturday'
    default:
      return null;
  }
}

export const isOneWeekOld = (date) => {
  date = moment(date);

  const oneWeekAgo = moment().add(-7, 'days');

  return date <= oneWeekAgo
}

export const isOlderThanToday = (date) => {
  date = moment(date);
  const today = moment();

  return today > date;
}
