import React, { useState } from 'react';
import { Formik } from 'formik';
import { ProcessNewFund } from './ProcessNewFund';
import { gql , useMutation, useQuery } from '@apollo/client';
import { CURRENT_USER } from '../../queries/currentUser';
import DataState from '../DataState';
import * as yup from 'yup';

const REQUEST_USE_OF_FUNDS = gql`
  mutation RequestUseOfFunds(
    $dealerId: ID!
    $amount: Decimal!
    $account: String!
    $memo: String!
  ) {
    requestUseOfFunds(
      dealerId: $dealerId
      amount: $amount
      account: $account
      memo: $memo
    ) {
      errors { path, message }
    }
  }
`

const NewFundRequest = ({ showModal, setShowModal, dealerId }) => {
  const { loading: userLoading, error: userError, data } = useQuery(CURRENT_USER);
  const [requestUse, { loading, error }] = useMutation(REQUEST_USE_OF_FUNDS, {
    onCompleted(values) {
      setShowModal(false);
      window.location.reload();
    }
  })

  const handleSubmit = (values) => {
    requestUse({ variables: values });
  }

  if (loading || userLoading) return <DataState.Loading />;
  if (error || userError) return <DataState.Error error={error || userError} />;

  return (
    <Formik
      initialValues={{
        dealerId: parseInt(dealerId),
        amount: '',
        account: '',
        memo: ''
      }}
      onSubmit={handleSubmit}
      validationSchema={yup.object({
        amount: yup
          .number()
          .required(),
        account: yup
          .string()
          .required(),
        memo: yup
          .string()
          .required(),
      })}
    >
      <ProcessNewFund
        showModal={showModal}
        setShowModal={setShowModal}
        handleSubmit={handleSubmit}
      />
    </Formik>
  )
}

export default NewFundRequest
