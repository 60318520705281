export const optionStyle = {
  justifyContent: 'space-between'
}

export let modalBodyStyle = {
  height: "100%",
  paddingBottom: "20px",
};

export const receiptButtons = {
  display: 'flex',
  justifyContent: 'space-around',
};

export const dotsStyle = {
  display: 'flex',
  justifyContent: 'center'
}

export const styledError = {
  marginLeft: "16px",
  marginTop: "0px",
};

export const autoCompleteStyle = {
  width: "350px",
  paddingLeft: "10px"
}

export const divStyle = {
  display: "flex",
  alignItems: "center",
};

export const inputStyle = {
  marginLeft: "12px",
  height: "30px",
};

export const inlineInputStyle = {
  ...inputStyle,
  marginLeft: "0px",
};

export const labelStyle = {
  marginTop: "0px",
};

export const paymentDivStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
};
