import * as Colors from '../styles/colors';
import { Spacing } from '../styles';

export const greenText = {
  color: Colors.xlGreen,
}

export const boldText = {
  fontWeight: 750,
  marginLeft: '2%',
}

export const bottomBorder = {
  height: '5px',
  width: '100%',
  backgroundColor: Colors.xlOrange,
  marginTop: '20px',
}

export const imageContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const rowStyle = {
  justifyContent: 'center'
}

export const buttonRow = {
  ...Spacing.buttonRow,
  margin: '0%',
  width: '30%',
  marginLeft: '35%',
  marginTop: '20px'
}

export const expiresOn = {
  fontSize: '12px'
}
