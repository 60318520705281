import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../contexts/AuthContext';
import { Typography } from '../../styles';
import Table from 'react-bootstrap/Table';
import { useFormikContext } from 'formik';
import { Form as FormikForm } from 'formik';
import * as StringHelper from '../../utils/stringHelpers';
import * as BasicForm from '../../elements/forms';
import * as Buttons from '../../elements/buttons';
import { Spacing } from '../../styles';
import { Colors } from '../../styles';
import './payments.css';
import * as yup from 'yup';
import { StyledErrorMessage } from '../../elements/forms';
import { FormBody } from './FormBody';
import { SortButtons, handleSort } from './Sort';
import Form from 'react-bootstrap/Form';

export const schema = yup.object({
})

export const formNumberStyle = {
  textAlign: 'right',
}

const columnHeaderStyle = {
  minWidth: '80px',
  borderBottom: 'none',
}

const summaryRow = {
  display: 'flex',
  justifyContent: 'space-evenly',
}

const summaryText = {
  fontSize: '40',
  fontWeight: 'bold'
}

export const PayForm = ({ data, total, setOrderedData, handleSubmit }) => {
  const [paymentTotal, setPaymentTotal] = useState(0);
  const [principalTotal, setPrincipalTotal] = useState(0);
  const [interestTotal, setInterestTotal] = useState(0);
  const [insuranceTotal, setInsuranceTotal] = useState(0);
  const [reservesTotal, setReservesTotal] = useState(0);
  const [feesTotal, setFeesTotal] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const [orderedColumn, setOrderedColumn] = useState(null);
  const [reordered, setReordered] = useState(false);
  const [dealerFees, setDealerFees] = useState(false);
  const { values, errors } = useFormikContext();

  const auth = useContext(AuthContext);

  const togglePayment = () => {
    let checked = dealerFees === true
    checked = (!checked)
      ? setDealerFees(true)
      : setDealerFees(false);
  }

  if (reordered) {
    delete values.quoteCarsAttributes;
    delete values.maxPrincipal;
  }

  return (
    <FormikForm>
      {(() =>
        {if (total > 0) {
          return (
            <>
              <div style={summaryRow}>
                <div style={{padding: "20px"}}>
                  <Form.Check
                    label="Pay Dealer Fees Only"
                    checked={(dealerFees === true)}
                    onClick={() => togglePayment()}
                  />
                </div>
              </div>
            </>
          )
        }
      })()}
    <div style={tableTopDivStyle}>
      <BasicForm.CustomStyleTextInput
        name="paymentDate"
        type="date"
        divStyle={inputDivStyle}
        label="Payment Date"
      />
      <BasicForm.CustomStyleTextInput
        name="search"
        type="search"
        style={searchStyle.input}
        divStyle={searchStyle.div}
        labelStyle={searchStyle.label}
        label="Search"
      />
    </div>
    <br/>
    <Table striped hover>
      <thead>
        <tr>
          <th
            className='ordered-column'
            style={Object.assign({}, columnHeaderStyle, {minWidth: '50px'})}
            onClick={() => handleSort(data, 'unitNumber', setOrderedColumn, orderedColumn, setOrderedData, setReordered)}
          >
            Unit
            <SortButtons
              columnName={'unitNumber'}
              selectedColumn={orderedColumn}
            />
          </th>
          <th style={columnHeaderStyle}>VIN</th>
          <th
            className='ordered-column'
            style={Object.assign({}, columnHeaderStyle, {minWidth: '90px'})}
            onClick={() => handleSort(data, 'year', setOrderedColumn, orderedColumn, setOrderedData, setReordered)}
          >
            Year
            <SortButtons
              columnName={'year'}
              selectedColumn={orderedColumn}
            />
          </th>
          <th style={columnHeaderStyle}>Make</th>
          <th style={columnHeaderStyle}>Model</th>
          <th
            className='ordered-column'
            style={Object.assign({}, columnHeaderStyle, {minWidth: '150px'})}
            onClick={() => handleSort(data, 'loanDate', setOrderedColumn, orderedColumn, setOrderedData, setReordered)}
          >
            Loan Date
            <SortButtons
              columnName={'loanDate'}
              selectedColumn={orderedColumn}
            />
          </th>
          <th
            className='ordered-column'
            style={columnHeaderStyle}
            onClick={() => handleSort(data, 'currentDueOn', setOrderedColumn, orderedColumn, setOrderedData, setReordered)}
          >
            Due Date
            <SortButtons
              columnName={'currentDueOn'}
              selectedColumn={orderedColumn}
            />
          </th>
          <th
            className='ordered-column'
            style={Object.assign({}, formNumberStyle, {minWidth: '50px'})}
            onClick={() => handleSort(data, 'totalDays', setOrderedColumn, orderedColumn, setOrderedData, setReordered)}
          >
            Days
            <SortButtons
              columnName={'totalDays'}
              selectedColumn={orderedColumn}
            />
          </th>
          <th>Principal</th>
          <th>Fees Due</th>
          <th style={formNumberStyle}>Interest Due</th>
          <th style={formNumberStyle}>Collateral Coverage</th>
          <th style={formNumberStyle}>Reserves</th>
          <th style={Object.assign({}, formNumberStyle)}>Total Due</th>
          <th>Payment Type</th>
        </tr>
      </thead>
      <tbody>
        <FormBody
          auth={auth}
          data={data}
          setPaymentTotal={setPaymentTotal}
          setFeesTotal={setFeesTotal}
          setPrincipalTotal={setPrincipalTotal}
          setInterestTotal={setInterestTotal}
          setInsuranceTotal={setInsuranceTotal}
          setReservesTotal={setReservesTotal}
          setDisabled={setDisabled}
          paymentTotal={paymentTotal}
          reordered={reordered}
          setReordered={setReordered}
          disabled={disabled}
          dealerFeesTotal={total}
          feesTotal={feesTotal}
          insuranceTotal={insuranceTotal}
          interestTotal={interestTotal}
          reservesTotal={reservesTotal}
          principalTotal={principalTotal}
          dealerFees={dealerFees}
        />
      </tbody>
    </Table>
      <div style={summaryRow}>
        <span style={summaryText}> Dealer Level Fees Total: {StringHelper.currency(total)}</span>
        <span style={summaryText}> Principal Total: {StringHelper.currency(principalTotal)}</span>
        <span style={summaryText}> Interest Total: {StringHelper.currency(interestTotal)}</span>
        <span style={summaryText}> Collateral Coverage Total: {StringHelper.currency(insuranceTotal)}</ span>
        <span style={summaryText}> Reserves Total: {StringHelper.currency(reservesTotal)}</span>
        <span style={summaryText}> Fees Total: {StringHelper.currency(feesTotal)}</ span>
        <span style={summaryText}> Payment Total: {StringHelper.currency(paymentTotal)}</span>
      </div >    <div style={Spacing.buttonRow}>
      <Buttons.Cancel type="cancel" text="Cancel" />
      <Buttons.Standard onClick={() => handleSubmit(values)} type="submit" text="Set Up Payment" disabled={disabled} />
    </div>
    </FormikForm>
  );
};

const searchStyle = {
  div: {
    display: 'flex',
    width: '20%',
    alignItems: 'center',
  },
  label: {
    paddingRight: '10px',
  },
  input: {
    border: `1px solid ${Colors.xlGreen}`,
  }
}

const tableTopDivStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
}

const inputDivStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '40%',
}
