import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationSquare, faCars } from '@fortawesome/pro-solid-svg-icons'

const nameLine = {
  paddingLeft: '10px',
  paddingRight: '10px'
}

const optionNameText = {
  fontSize: '0.9rem',
  marginLeft: '5px'
}

const optionLines = {
  paddingLeft: '10px',
  paddingRight: '10px',
  fontSize: '0.9rem',
  display: 'flex',
  flexWrap: 'wrap',
}

const pipeSeparator = {
  marginLeft: '5px',
  marginRight: '5px'
}

const ESResultOption = (props) => {
  const history = useHistory();
  let forceRefresh = null;
  let { option, hideMenu } = props;
  let optionType = option._index;
  // const redirectTo = (e, path) => {
  //   e.preventDefault();
  //   history.push(path);
  // }

  switch(optionType) {
    case 'floored_cars':
      forceRefresh = useRouteMatch('/floored_cars/')
      return (
        <div>
          <div className='es-option-top-line' style={nameLine}>
            <FontAwesomeIcon icon={faCars} />
            <Link to={`/cars/${option._id}?forceRefresh=${forceRefresh !== null ? true : false}`}
              style={optionNameText} onClick={() => hideMenu()}
            >
              {option._source.name}
            </Link>
          </div>
          <div className='es-option-vin-line' style={optionLines}>
            {option._source.vin}
          </div>
        </div>
      )
      break;
    default:
      return false;
      break;
  }
}

export default ESResultOption;
