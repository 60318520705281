import React, { createContext, Component } from 'react';

import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

const AuthContext = createContext();

const CURRENT_USER = gql`
  query {
    currentUser {
      id
      policies {
        resource
        actions {
          name
        }
      }
      multiplePeople
    }
  }
`;

export class AuthProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: undefined,
      load: props.load
    }

    this.getUser().then(user => this.setState({user}));
  }

  async getUser() {
    if (!this.state.load) return undefined;
    if (this.state.user) return this.state.user;

    const { data } = await this.props.client.query(
      {query: CURRENT_USER}
    );

    return data.currentUser;
  }

  hasPolicy(resource, action) {
    if (!this.state.user) return false;

    const policies = this.state.user.policies;

    const resource_policies = policies
      .find(policy_data => policy_data.resource === resource);

    const resource_action = resource_policies && resource_policies.actions.
      find(action_data => {
        return action_data.name === action || action_data.name === 'manage';
      });

    const all_policies = policies
      .find(policy_data => policy_data.resource === 'All');

    const all_action = all_policies && all_policies.actions.
      find(action_data => {
        return action_data.name === action || action_data.name === 'manage';
      });

    return (!!(resource_action || all_action));
  }

  render() {
    const value = {
      hasPolicy: (resource, action) => this.hasPolicy(resource, action),
      user: this.state.user
    };

    return (
      <AuthContext.Provider value={value}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthContext;
