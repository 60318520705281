/**
 * XLFunding
 *
 * @format
 * @flow
 */

import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { AUTH_TOKEN, DEALER_PERSON_ID } from './constants';
import secrets from './secrets';
import { ApolloProvider } from '@apollo/client';
import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/link-error';
import { createUploadLink } from 'apollo-upload-client'
import { AuthProvider } from './contexts/AuthContext';
import { VersionProvider, checkVersionLink } from './contexts/VersionContext';
import Login from './components/Login';
import PasswordSetup from './components/PasswordSetup';
import ChooseDealerPerson from './components/ChooseDealerPerson';
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import TopNavigation from './components/TopNavigation'

const uploadLink = createUploadLink({
  uri: secrets.api.baseUri + '/graphql',
  headers: {
    authorization: localStorage.getItem(AUTH_TOKEN),
    jwt_aud: secrets.api.jwtAud,
    [DEALER_PERSON_ID]: (localStorage.getItem(DEALER_PERSON_ID) || ''),
  }
});

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    if (networkError.statusCode === 401) {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(DEALER_PERSON_ID);
      window.location.assign('/');
    }
  }
});

const client = new ApolloClient({
  link: from([checkVersionLink, errorLink, uploadLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    }
  }
});

const App: () => React$Node = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const inviteToken = params.get('invite_token')
  const resetToken = params.get('reset_password_token')
  const authToken = localStorage.getItem(AUTH_TOKEN);
  const dealerPersonId = authToken && localStorage.getItem(DEALER_PERSON_ID);

  let loginInfo = null;

  if (inviteToken) {
    loginInfo = <PasswordSetup token={inviteToken} token_type="invite" />
  } else if (resetToken) {
    loginInfo =<PasswordSetup token={resetToken} token_type="reset" />
  } else if (!authToken) {
    loginInfo = <Login />
  } else if (authToken && !dealerPersonId) {
    loginInfo = <ChooseDealerPerson />
  }

  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <AuthProvider load={dealerPersonId} client={client}>
          <VersionProvider>
            <div>
              {!loginInfo && <TopNavigation />}
              <div style={{ backgroundColor: '#f5f5f5', overflow: 'hidden' }} >
                {loginInfo}
              </div>
            </div>
          </VersionProvider>
        </AuthProvider>
      </ApolloProvider>
    </BrowserRouter >
  );
};

export default App;
