import { useState } from 'react';

export const useAlert = () => {
  const [showAlert, toggleAlert] = useState(false)
  const dismissAlert = () => toggleAlert(false)

  const displayAlert = () => {
    toggleAlert(true)
    setTimeout(dismissAlert, 5000)
  };

  return [
    showAlert,
    displayAlert
  ]
}
