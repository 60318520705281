export const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
export const zipRegExp = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/
export const specialCharacterRegExp = /(?=.*[!@#$%^&*-])/

const wordsToExcludeFromTitleCase = [
  'llc',
  'dba',
  'usa',
  'vip',
  'pa',
  'ny',
  'ca',
]

export const titleCase = (string) => {
  if (!string) return ''

  return string.toLowerCase().split(' ').map(function (word) {
    if (wordsToExcludeFromTitleCase.includes(word)) {
      return word.toUpperCase();
    }

    if (!word[0]) return;

    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
}

export const truncate = (word, limit, trailing) => {
  if (!word || !limit) return word;

  let trailingChar = trailing || '...'

  if (word.length <= limit) {
    trailingChar = '';
  }

  return `${word.substring(0, limit)}${trailingChar}`
}

export const currency = (amount) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });


  return formatter.format(amount);
}
