import React, { useEffect, useRef, useState } from 'react';
import * as BasicForm from '../../../elements/forms';
import DataState from '../../DataState';
import { useFormikContext } from 'formik';
import { ErrorMessage } from '../../../elements/ErrorMessage';
import * as Options from './Options';
import { currency } from '../../../utils/stringHelpers';
import { optionStyle, paymentDivStyle } from './Style';

export const Form = props => {
  const {
    amount,
    dealerName,
    reserveBalance,
    setDisabled,
    quoteAmount
  } = props;

  const {
    setFieldValue,
    errors,
    values,
    values: {advanceType}
  } = useFormikContext();

  const advanceTypeRef = useRef('source');

  useEffect(() => {
    setFieldValue('advanceType', 'source')
  }, []);

  (Object.keys(errors).length > 0)
    ? setDisabled(true)
    : setDisabled(false)

  useEffect(() => {
      if (
        advanceTypeRef.current !== values.advanceType &&
        values.advanceType !== ''
      ) {
        setFieldValue('txnType', '');
        setFieldValue('name', '');
        setFieldValue('reference', '');
        setFieldValue('bankAccountId', '');
        setFieldValue('accountId', '');

        advanceTypeRef.current = values.advanceType
      }

    }, [advanceType])

  let reserveOption;
  if (parseFloat(reserveBalance) < quoteAmount) {
    reserveOption = `Reserve Balance is too low for this transaction.
      Payment Amount: ${currency(quoteAmount)} | Reserve Balance:
      ${currency(reserveBalance)}
    `;
  } else if (parseFloat(reserveBalance) < amount) {
    reserveOption = `Reserve Balance is too low for this transaction.
      Payment Amount: ${currency(amount)} | Reserve Balance:
      ${currency(reserveBalance)}
    `;
  } else {
   reserveOption =
    <BasicForm.Radio
      name="advanceType"
      type="radio"
      label={`Dealer Reserve ${currency(reserveBalance)}`}
      value="Dealer Reserve"
    />
  }

  return <div className="container">
    <div
      style={
        Object.assign({}, paymentDivStyle, optionStyle)
      }
    >
    </div>
    {
      advanceType && advanceType === 'source' &&
      <Options.AdvanceTypeOptions />
    }
  </div>
}
