import React, { useState } from 'react';
import {
  View,
  Text,
  TextInput,
  Button
} from 'react-native';
import logo from '../components/images/logo.png';
import { Formik, Form } from 'formik';
import * as BasicForm from '../elements/forms';
import { AUTH_TOKEN } from '../constants';
import secrets from '../secrets';
import * as Yup from 'yup';
import * as Buttons from '../elements/buttons';
import * as StringHelpers from '../utils/stringHelpers';
import { equalTo } from '../utils/passwordMatchValidation';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { loginWrapper, signInText } from './Login';

const PasswordSetup = ({ token, token_type }) => {
  const handleSubmit = (values) => {
    const password = values.password;
    const passwordConfirmation = values.passwordConfirmation;
    const token = values.token;

    const options = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'JWT_AUD': secrets.api.jwtAud
      },
      body: JSON.stringify({ token, password, token_type })
    };

    fetch(secrets.api.baseUri + '/confirm/confirm', options)
      .then(response => {
        if (!response.ok) {
          alert('Login failed, please retry');
        } else {
          response.json().then(data => {
            const options = {
              method: 'post',
              headers: {
                'Content-Type': 'application/json',
                'JWT_AUD': secrets.api.jwtAud
              },
              body: JSON.stringify({
                dealer_user: { email: data.email, password }
              })
            };

            fetch(secrets.api.baseUri + '/dealer_users/sign_in', options)
              .then(response => {
                if (!response.ok) {
                  alert('Login failed, please retry');
                } else {
                  localStorage.setItem(AUTH_TOKEN, response.headers.get('authorization'));
                  window.location.assign('/');
                }

                return response.json();
              });
          })
        }
      });

  };

  Yup.addMethod(Yup.string, 'equalTo', equalTo);
  return (
    <>
      <div style={loginWrapper}>
        <img src={logo} alt="Logo" height="72" width="72" style={{ margin: '0 auto' }} />
        <h3 style={signInText}>Please Set Your Password</h3>
        <Formik
          initialValues={{
            email: '',
            password: '',
            token: token
          }}

          validationSchema={Yup.object({
            password: Yup
              .string()
              .min(8, 'Password must have 8 or more characters')
              .matches(StringHelpers.specialCharacterRegExp, { message: "Password must contain at least one special character" }),
            passwordConfirmation: Yup
              .string()
              .equalTo(Yup.ref('password'), 'Passwords must match'),
          })}
          onSubmit={handleSubmit}
        >
          <Form>
            <BasicForm.PasswordInput
              name="password"
              type="password"
              placeholder="Password"
            />
            <BasicForm.PasswordInput
              name="passwordConfirmation"
              type="password"
              placeholder="Type Your Password Again"
            />
            <Buttons.SignIn type="submit" text="Set Password & Sign In" />
          </Form>
        </Formik>
      </div >
    </>
  );
}

export default PasswordSetup;
