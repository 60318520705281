import React from 'react';
import { Text, View } from 'react-native';
import { AUTH_TOKEN } from '../constants';

const logout = () => {
  localStorage.removeItem(AUTH_TOKEN);
  window.location.assign('/');
}

const Loading = () => {
  return <Text>Loading...</Text>
};

const Error = ({error}) => {
  return (
    <View>
      <Text>Error: {error.message}</Text>
      <a href="#" onClick={logout}>Logout</a>
    </View>
  )
};

export default { Loading, Error };
