import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import logo from 'components/src/components/images/logo.png';
import DataState from 'components/src/components/DataState';
import { loginWrapper, signInText } from './Login';
import { Helmet } from 'react-helmet';
import { AUTH_TOKEN, DEALER_PERSON_ID } from '../constants';
import { xlGreen } from 'components/src/styles/colors';
import * as Buttons from 'components/src/elements/buttons';

const DEALER_PEOPLE = gql`
  query DealerUserPeople {
    dealerUserPeople {
      id
      fullName
      dealer {
        name
        dealerStatus { canLogin }
      }
    }
  }
`;

const dealerStyle = {
  borderBottom: '1px solid #606060',
  textAlign: 'center',
  cursor: 'pointer',
};

const disabledDealerStyle = {
  ...dealerStyle,
  color: '#999999',
  cursor: 'default',
};

const dealerNameStyle = {
  fontSize: '1.5em'
};

const Dealer = ({ dealerPerson }) => {
  const canLogin = dealerPerson.dealer.dealerStatus.canLogin;

  const handleClick = () => {
    localStorage.setItem(DEALER_PERSON_ID, dealerPerson.id);
    window.location.assign('/');
  };

  return (
    <div
      onClick={canLogin ? handleClick : undefined}
      style={canLogin ? dealerStyle : disabledDealerStyle}
    >
      <div style={dealerNameStyle}>{dealerPerson.dealer.name}</div>
      <div>{dealerPerson.fullName}</div>
      {!canLogin && (
        <div style={{fontWeight: 'bold'}}>
          Account is currently unavailable.<br />
          Contact your account representative.
        </div>
      )}
    </div>
  );
};

const logout = () => {
  localStorage.removeItem(AUTH_TOKEN);
  window.location.assign('/');
}

const ChooseDealerPerson = () => {
  const { loading, error, data } = useQuery(DEALER_PEOPLE);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  let message;
  let accessible;

  if (data) {
    accessible = data.dealerUserPeople.filter(person =>
      person.dealer.dealerStatus.canLogin
    );

    if (0 === accessible.length) {
      message = 'Your account is currently unavailable, contact your market representative.';
    } else if (1 === data.dealerUserPeople.length && 1 === accessible.length) {
      localStorage.setItem(DEALER_PERSON_ID, accessible[0].id);
      window.location.assign('/');
      message = 'Loading your account';
    }
  }

  return (
    <>
      <Helmet>
        <title>XL Funding - Choose Dealer</title>
      </Helmet>
      <div style={loginWrapper}>
        <img src={logo} alt="Logo" height="72" width="72" style={{ margin: '0 auto' }} />
        {message ? (
          <h3 style={signInText}>{message}</h3>
        ) : (
          <>
            <h3 style={signInText}>Choose Dealer</h3>
            {data.dealerUserPeople.map(dealerPerson => (
              <Dealer key={dealerPerson.id} dealerPerson={dealerPerson} />
            ))}
          </>
        )}
        <br />
        <Buttons.Link
          text="Logout"
          onClick={logout}
          style={{color: xlGreen}}
        />
      </div>
    </>
  );
};

export default ChooseDealerPerson;
