import React, { useEffect, useRef, useState } from "react";
import { useFormikContext } from "formik";
import * as BasicForm from "../../../elements/forms";
import { ErrorMessage } from "../../../elements/ErrorMessage";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { useParams, Redirect } from "react-router-dom";
import DataState from "../../DataState";
import { BANK_ACCOUNTS, ACCOUNTS } from './Queries';
import {
  styledError,
  autoCompleteStyle,
  divStyle,
  inlineInputStyle,
  labelStyle,
  inputStyle,
  paymentDivStyle
} from './Style';
import Select from 'react-select';

export const AdvanceTypeOptions = () => {
  const {
    errors,
    setFieldValue,
    values: { sourceType },
  } = useFormikContext();

  useEffect(() => {
    setFieldValue("bankAccountId", "");
  }, [sourceType]);

  return <Dealer key="dealer" />;
};

const bankAccountOption = account => ({
  value: account.id,
  label: `${account.accountName} ${account.accountNumberMasked}`
});

const Dealer = props => {
  const { formattedSourceType } = props;
  const { handleBlur, setFieldValue, values, errors } = useFormikContext();

  const { loading, error, data } = useQuery(BANK_ACCOUNTS);

  if (loading || !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { bankAccounts, primaryBankAccount } = data.currentDealer;

  if (!values.bankAccountId && primaryBankAccount) {
    setFieldValue('bankAccountId', primaryBankAccount.id);
  }

  if (0 === bankAccounts.length) {
    return (
      <div>No Bank Accounts. Contact Your Account Manager</div>
    );
  } else if (1 === bankAccounts.length) {
    return (
      <div>
        Dealer's Source ACH:
        {bankAccountOption(primaryBankAccount).label}
      </div>
    )
  } else {
    const formattedBankAccounts = bankAccounts.map(account => (
      bankAccountOption(account)
    ));

    return (
      <div>
        Select the Dealer's Source ACH:
        <Select
          isMulti={false}
          options={formattedBankAccounts}
          defaultValue={bankAccountOption(primaryBankAccount)}
          onChange={(selected) => {
            setFieldValue('bankAccountId', selected.value)
          }}
        />
        <BasicForm.InlineStyledErrorMessage>
          {errors.bankAccountId}
        </BasicForm.InlineStyledErrorMessage>
      </div>
    );
  }
};

export const ExternalTypeOptions = props => {
  const { quoteAmount, setDisabled } = props;
  const {
    setFieldValue,
    errors,
    values,
    values: { txnType },
  } = useFormikContext();

  let [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    setPaymentMethod(txnType);
  }, [txnType]);

  useEffect(() => {
    values.accountId = parseInt(values.accountId) || "";
  }, [values.accountId]);

  const { loading, error, data } = useQuery(ACCOUNTS);

  if (loading && !data) return null;
  if (error) return <DataState.Error error={error} />;

  const { accounts } = data;

  const paymentsBank = accounts.find((account) => {
    return account.special && "payments_bank" === account.special.key;
  });

  if (!values.accountId) {
    values.accountId = paymentsBank.id;
  }

  (values.total < quoteAmount) ? setDisabled(true) : setDisabled(false)

  return [
    <PaymentMethod key="txnType" errors={errors} />,
    txnType && paymentMethod && (
      <div>
        <Advance key="check" type={paymentMethod} />
        <div>
          <BasicForm.Select
            name="accountId"
            label="Payment Made to GL Account"
          >
            <option value="">Select an Account</option>
            {setOptionValues(accounts)}
          </BasicForm.Select>
          <BasicForm.StyledErrorMessage>
            {errors.accountId}
          </BasicForm.StyledErrorMessage>
          <BasicForm.TextInput
            name="total"
            label="Payment Amount"
            type="number"
          />
          <BasicForm.InlineStyledErrorMessage style={{ marginTop: "8px" }}>
            {
              values.total && parseFloat(values.total) <
                parseFloat(quoteAmount) && (
                  <p style={{ marginBottom: "0px" }}>
                    Must be greater than or equal to the payment amount.
                  </p>
                )
            }
          </BasicForm.InlineStyledErrorMessage>
        </div>
      </div>
    ),
  ];
};

const PaymentMethod = props => {
  const { errors } = props
  return (
    <div style={paymentDivStyle}>
      What will the method of payment be?
      <BasicForm.Radio
        name="txnType"
        type="radio"
        label="Check"
        value="Check"
      />
      <BasicForm.Radio name="txnType" type="radio" label="ACH" value="ACH" />
      <BasicForm.Radio
        name="txnType"
        type="radio"
        label="Wire Transfer"
        value="Wire"
      />
      <BasicForm.Radio name="txnType" type="radio" label="Cash" value="Cash" />
      <BasicForm.InlineStyledErrorMessage>
        {errors && errors.txnType}
      </BasicForm.InlineStyledErrorMessage>
    </div>
  );
};

const Advance = props => {
  const { values, setFieldValue } = useFormikContext();
  const { type } = props;
  let advanceToLabel = type;
  if (type !== "Check") advanceToLabel = `${type} Reference`;

  if (type !== "Cash") {
    return (
      <div style={divStyle}>
        <div>
          <BasicForm.TextInput
            name="name"
            type="text"
            style={inlineInputStyle}
            label={`${type} From:`}
            labelStyle={labelStyle}
          />
        </div>
        <div style={{ paddingLeft: "20px" }}>
          <BasicForm.TextInput
            name="reference"
            type="text"
            label={`${advanceToLabel} Number:`}
            style={inlineInputStyle}
            labelStyle={labelStyle}
          />
        </div>
      </div>
    );
  }

  if (type === "Cash") {
    values.name = "";

    return (
      <div style={divStyle}>
        <div>
          <BasicForm.TextInput
            name="reference"
            type="text"
            label="Reference"
            style={inlineInputStyle}
            labelStyle={labelStyle}
          />
        </div>
      </div>
    );
  }
};

const setOptionValues = props => {
  const { accounts } = props;
  return accounts.map((account) => {
    const name = `GL${account.gl} - ${account.name}`;
    const id = parseInt(account.id);

    return <option value={id}>{name}</option>;
  });
};
