import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FLOORED_CAR } from '../../queries/viewQueries';
import { useQuery } from '@apollo/client';
import DataState from '../DataState';
import { Document, Page, pdfjs } from 'react-pdf';
import { isMobile, isSafari } from "react-device-detect";
import { page, containerStyle, orange, green, tracking } from './style';

const WatermarkedTitlePdf = () => {
  const carId = parseInt(useParams().carId)
  const [numPages, setNumPages] = useState(null);

  const { loading: carLoading, error: carError, data: carData } = useQuery(
    FLOORED_CAR, { variables: { id: carId }}
  )

  pdfjs.GlobalWorkerOptions.workerSrc = `
    //cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js
  `;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  if (carLoading && !carData) return <DataState.Loading />;
  if (carError) return <DataState.Error error={carError} />;



  let watermarkedArray = carData.flooredCar.titleLogs.filter((title) => {
    return (title.watermarkedFileUpload !== null)
  })

  const src = watermarkedArray[watermarkedArray.length - 1]
    ?.watermarkedFileUpload.fileUrl
  const name = watermarkedArray[watermarkedArray.length - 1]
    ?.watermarkedFileUpload.name

  let my_array = carData.flooredCar.titleLogs
  let last_element = my_array[my_array.length - 1];

  const titleStatus = last_element?.titleStatus?.name;

  let newArray = carData.flooredCar.titleLogs.filter((title) => {
    return (title.trackingNumber !== null)
  });

  const trackingNumber = newArray[newArray.length-1]?.trackingNumber;

  const CheckView = () => {
    if (isMobile || isSafari) {
      return (
        <div style={containerStyle}>
          <Document
            file={src}
            options={{ workerSrc: "/pdf.worker.js" }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      )
    } else {
      return (
        <div style={page}>
          <embed src={src} style={containerStyle} />
        </div>
      )
    }
  }

  return (
    <>
      <h3 style={orange}>Watermarked Title</h3>
      <div style={green}>
        Name: {name}
      </div>
      <div style={green}>
        Title Status: {titleStatus}
      </div>
      <div style={green}>
        <div style={tracking}>
          Tracking Number: {trackingNumber}
        </div>
      </div>
      <CheckView />
    </>
  )
}

export default WatermarkedTitlePdf
