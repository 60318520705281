import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { Spacing } from '../styles';
import { currency } from '../utils/stringHelpers';
import { DateHelpers } from '../utils';

const BasicAlert = (props) => {
  if (!props.componentType) return;

  let type;
  if (props.type === 'update' || !props.type) {
    type = 'updated'
  } else {
    type = props.type
  }

  return (
    <Alert variant='success' style={Spacing.alerts}>
      This {props.componentType} has been {type}.
    </Alert>
  );
}

export const RepoAlert = ({ location }) => {
  return (
    <Alert variant='danger'>
      This car has been marked repo.  Its current location is: {location}.
    </Alert>
  )
}

export const CustomAlert = ({ message }) => {
  return (
    <Alert variant="danger">{message}</Alert>
  )
}

export const AchAlert = ({ date, amount, carAmount, type }) => {
  return (
    <Alert variant='danger'>
      This car has a Pending ACH.
      The payment is a {type} for {currency(carAmount)}&nbsp;
      (Total including other cars and fees: {currency(amount)})
      scheduled for {DateHelpers.renderDate(date)}.
    </Alert>
  )
}

export default BasicAlert;
