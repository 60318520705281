import React, { useEffect, useState } from "react";
import * as BasicForm from "../../elements/forms";
import { useFormikContext } from "formik";
import { getQuoteData, PrincipalError } from "./Functions";
import * as StringHelper from "../../utils/stringHelpers";
import * as DateHelper from "../../utils/dateHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faMinusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FeeDetails, feeButtonStyle } from "./Fees";
import { formNumberStyle } from "./Form";
import { getSearchValues, searchLookup } from "./Search";

const disabledArray = [];
const loadingArray = [];

const inputStyle = {
  display: "inline-flex",
};

const handleEnableItems = (disabledItems, setDisabledItems) => {
  if (disabledItems) setDisabledItems(false);
};

const handleRoleCheck = (roleData, disabledItems, setDisabledItems, auth) => {
  const role = roleData[0];
  const action = roleData[1];

  auth.hasPolicy(role, action)
    ? handleEnableItems(disabledItems, setDisabledItems)
    : setDisabledItems(true);
};

export const FormBody = ({
  auth,
  data,
  disabled,
  setPaymentTotal,
  setPrincipalTotal,
  setReservesTotal,
  setInterestTotal,
  setInsuranceTotal,
  setFeesTotal,
  setDisabled,
  paymentTotal,
  reordered,
  setReordered,
  dealerFeesTotal,
  principalTotal,
  reservesTotal,
  interestTotal,
  insuranceTotal,
  feesTotal,
  dealerFees,
}) => {
  const {
    values,
    setValues,
    setFieldValue,
    touched,
    handleBlur,
  } = useFormikContext();

  let total = 0;
  let totalPrincipal = 0;
  let totalFeeAmount = 0;
  let totalInterest = 0;
  let totalInsurance = 0;
  let totalReserves = 0;

  total += parseFloat(dealerFeesTotal);
  data = data.map((car, index) => {
    const [showFees, setShowFees] = useState(false);
    const [disabledItems, setDisabledItems] = useState(false);

    let feeDetails;

    let quoteData = getQuoteData(car, index, reordered);

    let selected = false;
    if (
      values.quoteCarsAttributes &&
      values.quoteCarsAttributes[index] &&
      values.quoteCarsAttributes[index]["paymentType"] !== ""
    )
      selected = true;

    let loading = (
      <p key={index} style={{ marginBottom: "0px" }}>
        loading...
      </p>
    );
    if (!values.quoteCarsAttributes || !values.quoteCarsAttributes[index])
      return loading;

    if (!quoteData) loadingArray[index] = true;

    if (quoteData) {
      quoteData = quoteData.quoteAmounts;
      loadingArray[index] = false;
    }

    const totalDue =
      StringHelper.currency(values.quoteCarsAttributes[index]?.totalDue) ||
      loading;

    const reserves = values.quoteCarsAttributes[index].reserves;

    let calculatedTotalDue = null;
    let principal = 0;
    if (
      values.quoteCarsAttributes &&
      values.quoteCarsAttributes[index] &&
      quoteData
    ) {
      principal =
        values.quoteCarsAttributes[index]["principal"] === ""
          ? 0
          : parseFloat(values.quoteCarsAttributes[index]["principal"]);

      if (quoteData.feeDetails) {
        const currentFeeIds = Object.keys(
          values.quoteCarsAttributes[index]["feeDetails"]
        );

        if (quoteData.feeDetails.length === 0) {
          const feeDue = 0;
          currentFeeIds.forEach((fee, feeIndex) => {
            values.quoteCarsAttributes[index]["feeDetails"][feeIndex] = {
              [fee]: { amount: parseFloat(feeDue) },
            };
          });
        } else {
          quoteData.feeDetails.forEach((fee, feeIndex) => {
            const feeId = fee.id;
            let feeDue = parseFloat(fee.due);

            values.quoteCarsAttributes[index]["feeDetails"][feeIndex] = {
              [feeId]: { amount: feeDue },
            };
          });
        }
      }
    }

    // Fees
    let totalFees = 0;
    if (quoteData) {
      let currentFees = values.quoteCarsAttributes[index]["feeDetails"];
      currentFees = currentFees.filter((fee) => fee !== undefined);

      currentFees.map((fee) => {
        const amount = parseFloat(Object.values(fee)[0].amount);

        totalFees += amount;
      });

      feeDetails = quoteData.feeDetails;

      const interest = parseFloat(quoteData.interest);
      const insurance = parseFloat(quoteData.insurance);
      const reservesAmount = parseFloat(reserves);
      const totalFeesAmount = parseFloat(totalFees);

      // Total Due
      calculatedTotalDue =
        principal + totalFeesAmount + interest + insurance + reservesAmount;

      if (values.quoteCarsAttributes[index]["paymentType"] !== "") {
        total += calculatedTotalDue;
        totalPrincipal += principal;
        totalFeeAmount += totalFeesAmount;
        totalInterest += interest;
        totalInsurance += insurance;
        totalReserves += reservesAmount;
      }

      /**
       * TODO: This should be an object, but for some reason it triggers
       * render hell to try to set state on an object.
       */
      setPaymentTotal(total);
      setPrincipalTotal(totalPrincipal);
      setFeesTotal(totalFeeAmount);
      setInterestTotal(totalInterest);
      setInsuranceTotal(totalInsurance);
      setReservesTotal(totalReserves);

      calculatedTotalDue = StringHelper.currency(calculatedTotalDue);
    }

    // Search Functionality
    let searchValues = getSearchValues(car);
    let valueInSearch = searchLookup(searchValues, values);

    if (valueInSearch === false) return null;

    return (
      <tr key={index}>
        <td style={formNumberStyle}>{car.unitNumber}</td>
        <td>{car.vin}</td>
        <td>{car.year}</td>
        <td style={{ flexWrap: "wrap" }}>{car.make}</td>
        <td style={{ flexWrap: "wrap" }}>{car.model}</td>
        <td>{DateHelper.setDate(car.loanDate)}</td>
        <td>{DateHelper.setDate(car.currentDueOn)}</td>
        <td style={formNumberStyle}>{car.termData?.totalDays}</td>
        <td style={inputStyle}>
          <BasicForm.TextInput
            name={`quoteCarsAttributes[${index}][principal]`}
            type="number"
            touched={touched}
            onBlur={handleBlur}
            disabled
            onFocus={(e) => e.target.select()}
          />
        </td>
        <PrincipalError
          values={values}
          index={index}
          disabledArray={disabledArray}
        />
        <td style={{ width: "110px" }}>
          {(quoteData && `${StringHelper.currency(totalFees)}`) || loading}
          {quoteData &&
            showFees &&
            values.quoteCarsAttributes[index]["paymentType"] !==
              "principal" && (
              <FontAwesomeIcon
                icon={faMinusCircle}
                className="fee-details-button"
                style={feeButtonStyle}
                onClick={() => setShowFees(!showFees)}
              />
            )}
          {quoteData &&
            !showFees &&
            values.quoteCarsAttributes[index]["paymentType"] !==
              "principal" && (
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={feeButtonStyle}
                className="fee-details-button"
                onClick={() => setShowFees(!showFees)}
              />
            )}
          {showFees && (
            <FeeDetails
              feeDetails={feeDetails}
              index={index}
              disabledItems={disabledItems && selected}
            />
          )}
        </td>
        <td style={formNumberStyle}>
          {(quoteData && StringHelper.currency(quoteData.interest)) || loading}
        </td>
        <td style={Object.assign({}, formNumberStyle, { minWidth: "120px" })}>
          {(quoteData && StringHelper.currency(quoteData.insurance)) || loading}
        </td>
        <td style={Object.assign({}, formNumberStyle, { minWidth: "120px" })}>
          {(quoteData && StringHelper.currency(reserves)) || loading}
        </td>
        <td style={Object.assign({}, formNumberStyle, { minWidth: "120px" })}>
          {calculatedTotalDue || loading}
        </td>
        <td>
          <BasicForm.Radio
            name={`quoteCarsAttributes[${index}][paymentType]`}
            label="No Payment"
            value=""
            checked={values.quoteCarsAttributes[index]["paymentType"] === ""}
            onClick={() => handleEnableItems(disabledItems, setDisabledItems)}
          />
          { car.canCurtail &&
            <BasicForm.Radio
              name={`quoteCarsAttributes[${index}][paymentType]`}
              label="Curtailment"
              value="curtailment"
              checked={
                values.quoteCarsAttributes[index]["paymentType"] === "curtailment"
              }
              onClick={() =>
                handleRoleCheck(
                  ["Curtailment", "change"],
                  disabledItems,
                  setDisabledItems,
                  auth
                )
              }
            />
          }
          <BasicForm.Radio
            name={`quoteCarsAttributes[${index}][paymentType]`}
            label="Payoff"
            value="payoff"
            checked={
              values.quoteCarsAttributes[index]["paymentType"] === "payoff"
            }
            onClick={() => setDisabledItems(true)}
          />
        </td>
      </tr>
    );
  });

  if (
    disabledArray.includes(true) ||
    loadingArray.includes(true) ||
    paymentTotal === 0
  ) {
    setDisabled(true);
  } else setDisabled(false);

  setReordered(false);
  return data;
};
