import React, { useContext, useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import { useAlert } from '../hooks/useAlert';
import DataTable from '../elements/DataTable';
import AuthContext from '../contexts/AuthContext';
import { Helmet } from 'react-helmet';
import { DateHelpers, StringHelpers } from '../utils';
import BasicAlert from '../elements/BasicAlert';
import DataState from './DataState';
import { setAlertData } from '../utils/setAlertData';
import { useQuery } from '@apollo/client';
import { NOTIFICATIONS } from '../queries/viewQueries';
import { useHideableResource } from '../hooks/useHideableResource';
import { CURRENT_USER } from '../queries/commonQueries';
import Moment from 'react-moment';
import moment from 'moment';
import * as Buttons from '../elements/buttons';
import { useMutation } from '@apollo/client';
import './notification.css';
import TrackVisibility from 'react-on-screen';

const HIDE_NOTIFICATION = gql`
  mutation HideNotification($id: ID!) {
    hideNotification(
      id: $id
    ) {
      notification {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification(
    $id: ID!,
    $viewedAt: ISO8601DateTime!
  ) {
    updateNotification(
      id: $id
      viewedAt: $viewedAt
    ) {
      notification {
        id
        viewedAt
      }
    }
  }
`

const Notification = ({notification, isVisible}) => {
  let now = moment();
  const [updateMutation] = useMutation(UPDATE_NOTIFICATION)
  const [hideMutation] = useMutation(HIDE_NOTIFICATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: NOTIFICATIONS,
        variables: {ownerId: parseInt(notification.ownerId), ownerType: notification.ownerType}
      }
    ]
  })

  if (isVisible) {
   updateMutation({ variables: {id: notification.id, viewedAt: now} });
  }

  let linkText;
  if (notification.link != '' && notification.link != null) {
    linkText = "Click here to view."
  } else {
    linkText = ""
  }

  return (
    <div className="alert alert-primary" role="alert" key={notification.id}>
      { notification.notification }
        <a href={notification.link} className="alert-link">
          <div>
            {linkText}
          </div>
        </a>
      <Moment>
        { notification.createdAt }
      </Moment>
      <div className="topcorner">
        <Buttons.Link text="X" onClick={() => hideMutation( { variables: {id: notification.id} })} />
      </div>
    </div>
  )
}

const UserNotification = (props) => {
  const {loading, error, data} = useQuery(NOTIFICATIONS, { variables: {ownerId: parseInt(props.ownerId), ownerType: "DealerPerson" } })

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  return (
    data.notifications.length === 0
      ? <div className="zero">You have no new notifications.</div>
      : <div className="container pt-3">
        <h3>Notifications</h3>
        <div className="mx-5">
          {data.notifications.map(notification => {
            notification = { ...notification };
            const notificationId = parseInt(notification.id);
            return (
              <TrackVisibility once key={notification.id}>
                <Notification key={notification.id} notification={notification} />
              </TrackVisibility>
            )
          })}
        </div>
      </div>
  )
}

const Notifications: () => React$Node = (props) => {
  const [alertType, setAlertType] = useState();
  const [showAlert, displayAlert] = useAlert();
  const { loading, error, data } = useQuery(CURRENT_USER);
  const auth = useContext(AuthContext);

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const ownerId = data.currentUser.id;

  return (
    <>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <UserNotification ownerId={ ownerId } />
    </>
  );
};

export default Notifications;
