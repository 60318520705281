export const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 30px',
}

export const hrStyle = {
  width: '100%',
}

export const dataLabelStyle = {
  width: '50%',
  padding: '4px 2px',
  margin: '0 auto',
  lineHeight: 'normal',
}

export const dataValueStyle = {
  ...dataLabelStyle,
  textAlign: 'right',
  fontWeight: 'bold',
}

export const carImageStyle = {
  height: '100%',
  width: '100%'
}

export const noCarImageStyle = {
  ...carImageStyle,
  opacity: '0.6',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}

export const rowStyle = {
  padding: '2px 4px',
  borderBottom: '1px solid #AAAAAA',
  display: 'flex',
  alignItems: 'center',
}

export const greenRowStyle = {
  ...rowStyle,
  backgroundColor: '#C5DACF',
}

export const linkStyle = {
  color: 'blue'
}
