import React from 'react';
import * as Colors from '../styles/colors';
import { useHistory } from 'react-router-dom';

const normalButton = {
  marginTop: '6%',
  fontWeight: 550,
  width: '47%'
}
const standardButton = {
  ...normalButton,
  backgroundColor: Colors.xlGreen,
  borderColor: Colors.xlGreen,
}

export const cancelButton = {
  ...normalButton,
  backgroundColor: 'white',
  borderColor: Colors.xlGreen,
  color: Colors.xlGreen,
}
const signInButton = {
  marginTop: '8%',
  marginBottom: '3%',
  fontWeight: 550,
  backgroundColor: Colors.xlGreen,
  borderColor: Colors.xlGreen
}

const linkStyle = {
  background: 'none',
  border: 'none',
  padding: 0,
  color: Colors.xlGreen,
  cursor: 'pointer'
}

export const Standard = (props) => {
  return (
    <button type={props.type} className="btn btn-lg btn-primary btn-block" style={standardButton} {...props}>
      {props.text}
    </button>
  )
};

export const Link = (props) => {
  return (
    <button className='btn-Link' type={props.type} style={{ ...linkStyle, ...props.style }} onClick={props.onClick}>
      {props.text}
    </button >
  )
}

export const Cancel = (props) => {
  const history = useHistory();

  return (
    <a href="#" onClick={history.goBack} type={props.type} className="btn btn-lg btn-primary btn-block" style={cancelButton} >
      {props.text}
    </a >
  )
};

export const ModalCancel = (props) => {
  const history = useHistory();

  return (
    <a href="#" onClick={props.handleClose} type={props.type} className="btn btn-lg btn-primary btn-block" style={cancelButton} >
      {props.text}
    </a >
  )
};

export const SignIn = (props) => {
  return (
    <button type={props.type} className="btn btn-lg btn-primary btn-block" style={signInButton} >
      {props.text}
    </button>
  )
};


