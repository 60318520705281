import React from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';
import * as Buttons from '../../elements/buttons';
import * as BasicForm from '../../elements/forms';
import { Spacing, Typography } from '../../styles';
import DataState from '../DataState';
import { StringHelpers } from '../../utils/';
import { equalTo } from '../../utils/passwordMatchValidation';

const UPDATE_PASSWORD = gql`
  mutation UpdateCurrentDealerUserPassword(
    $password: String!
    $passwordConfirmation: String
  ) {
    updateCurrentDealerUserPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      dealerUser {
        id
      }
    }
  }
`;

function ChangePassword() {
  const history = useHistory();
  const [updateUserPassword] = useMutation(UPDATE_PASSWORD,
    {
      onCompleted() {
        history.push({
          pathname: '/',
          state: { alert: true, type: 'changed', componentType: 'password' },
        })
      }
    });

  yup.addMethod(yup.string, 'equalTo', equalTo);

  const updatePasswordSchema = yup.object({
    password: yup
      .string()
      .required('Required')
      .min(8, 'Password must have 8 or more characters')
      .matches(StringHelpers.specialCharacterRegExp, { message: "Password must contain at least one special character" }),
    passwordConfirmation: yup
      .string()
      .required('Required')
      .equalTo(yup.ref('password'), 'Passwords must match')
  });

  const PasswordForm = () => {
    return (
      <div style={Spacing.formWrapper}>
        <div style={Spacing.formHeader}>
          <h1 style={Typography.formHeader}>Update Password</h1>
        </div>
        <Formik
          initialValues={{
            password: '',
            passwordConfirmation: ''
          }}
          validationSchema={updatePasswordSchema}
          onSubmit={handleSubmit}
        >
          <FormikForm>
            <BasicForm.TextInput
              name="password"
              type="password"
              label="New Password"
            />
            <BasicForm.TextInput
              name='passwordConfirmation'
              type='password'
              label="Re-Type Password"
            />
            <div style={Spacing.buttonRow}>
              <Buttons.Cancel type="cancel" text="Cancel" />
              <Buttons.Standard type="submit" text="Submit" />
            </div>
          </FormikForm>
        </Formik>
      </div>
    )
  }

  const handleSubmit = (values) => {
    updateUserPassword({ variables: values });
  };

  return (
    <>
      <div style={Spacing.formContainer}>
        <PasswordForm />
      </div>
    </>
  );
}

export default ChangePassword;
