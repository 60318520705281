import React, { useEffect, useState } from 'react';
import { Text } from 'react-native';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import moment from 'moment'
import './datatable.css';
import { Typography, Spacing, Colors } from '../styles';
import $ from 'jquery';
import { DataTable as DataTableImport } from 'datatables.net';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import AuthLink from '../components/AuthLink';
import DataState from '../components/DataState';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router';
import * as Buttons from './buttons';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

$.DataTable = DataTableImport

require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-responsive');
require('datatables.net-responsive-dt');
require('datatables.net-searchpanes');
require('datatables.net-select');
require('datatables.net-colreorder-dt');

import {
  faPlusCircle,
} from '@fortawesome/pro-regular-svg-icons'

const LOAD_SETTINGS = gql`
  query($page: String!) {
  displaySettingForPage(
    page: $page
  ) {
    id
    settings
  }
}
`
const SAVE_SETTINGS = gql`
mutation SaveDisplaySettingForPage($page: String!, $settings: JSON!) {
    saveDisplaySettingForPage(
      page: $page
      settings: $settings
    ) {
      displaySetting { id, settings, page }
    }
  }
`

const textForCreateStyle = {
  fontSize: '16px',
  paddingLeft: '10px'
}

const DataTable = (props) => {
  let {
    dataSet,
    columns,
    pageTitle,
    userId,
    newRoute,
    subHeader,
    orderRows,
    addFilter,
    addColvis,
    setShowHidden,
    showHidden,
    unhideable,
    uncreatable,
    tableId,
    handleShowModal,
    handleShowEditModal,
    textForCreate,
    toggleData,
    toggleDataLabel,
    toggleDataState,
  } = props;

  if (!tableId) tableId = '#data-table';
  const divId = tableId.slice(1);

  const history = useHistory()

  const page = useLocation().pathname

  const id = parseInt(userId, 10)
  let ordering = true;
  if (orderRows === false) ordering = false;

  const { loading, error, data } = useQuery(LOAD_SETTINGS, {
    variables: {
      userId: id,
      page: page
    }
  });

  const [saveSettings] = useMutation(SAVE_SETTINGS)

  const createRoute = newRoute || `${page}/new`;

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;


  const { displaySettingForPage } = data;
  let displaySettings = null;
  if ( displaySettingForPage ) {
    displaySettings = displaySettingForPage.settings;
  }

  let table;

  $(document).ready(function () {
    const buttons = [];
    let language = {};

    if (addFilter) {
        buttons.push({
          extend: 'searchPanes',
          text: 'Filter',
          cascadePanes: false,
          className: 'btn toggle-vis filter',
          config: {
            layout: 'columns-4',
            cascadePanes: true,
            columns: [0, 2, 8, 13]
          }
        }
      )

      language = {
        searchPanes: {
          collapse: {0: 'Filter Options', _: 'Filter Options (%d)'}
        }
      }
    }

    if (addColvis) {
      buttons.push({
          extend: 'colvis',
          text: 'Show/Hide Columns',
          className: 'btn toggle-vis',
        })

      buttons.push({
          text: 'Reset Settings',
          className: 'btn toggle-vis reset',
          action: function () {
            table.state.clear();
            window.location.reload();
          }
      })
    }

    table = $(tableId).DataTable(
      {
        columns,
        buttons,
        language,
        ordering,
        dom: '<"#tableHeader"lf><t><ip>',
        data: dataSet,
        colReorder: true,
        pagingType: "full_numbers",
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, 'All']],
        retrieve: true,
        responsive: true,
        stateSave: true,
        columnDefs: [
          { responsivePriority: 1, targets: [0, 1] },
          { responsivePriority: 2, targets: -1 }
        ],
        stateLoadParams: function (settings, data) {
          if (displaySettings) {
            if (data.searchPanes) data.searchPanes.selectionList = [];

            data.search.search = '';
            data.ColReorder = displaySettings.ColReorder;
            data.columns = displaySettings.columns;
            data.order = displaySettings.order;
            data.length = displaySettings.length;
          }
        }
      }
    );

    const dataTablesFilter = $(`div${tableId}_filter`)
      table.buttons().container()
        .appendTo(dataTablesFilter);

    if (dataTablesFilter) $(`${tableId}_length`).addClass("dataTables_filter")


    // This filter menu gets duplicated if the user clicks dealers from nav if
    // already on the page. This function stops that from happening, but now
    // removes the datatables length option. TODO: This should be worked out.
    if (dataTablesFilter.length > 1) dataTablesFilter[0].remove();

    // stop table extension from duplicating when refreshing table data
    const pagination = $(`div${tableId}_paginate`);
    const info = $(`div${tableId}_info`);
    const showLength = $(`div${tableId}_length`);
    if (pagination && pagination.length > 1) pagination[1].remove();
    if (info && info.length > 1) info[1].remove();
    if (showLength && showLength.length > 1) {
      showLength[1].remove()
    }

  // Adds close button to filter options without duplication
    table.on('buttons-action', function (e, buttonApi, dataTable, node, config ) {
      if(config.hasOwnProperty('_panes')){
        if (!$(".dtsp-closePanes")[0]){
            $('.dtsp-titleRow').prepend(
                $('<button class="dtsp-closePanes">Close</button>').click(function(){
                    table.button().popover(false);
                })
            );
        }
      }
    });

    // Modal - Edit
    $(".editModal").click(function(event) {
      const id = parseInt(event.target.dataset.id)
      handleShowEditModal(id);
    });

    const overdueDateCells = Array.from($('.showOverdue'))
    overdueDateCells.forEach(cell => {
      const cellDate = new Date(cell.innerText);
      if (cellDate.getTime() < moment()) {
        $(cell).wrapInner("<span style='color: red'></span>")
      }
    })
  });

  history.listen((location) => {
    let data;
    if (table) data = table.state();

    if (data) saveTableSettings(data);
  })

  const saveTableSettings = (data) => {
    // saveSettings(
    //   { variables:
    //     {
    //       page: page,
    //       settings: data
    //     }
    //   })
  }

  const addRecord = {
    color: Colors.xlGreen,
    fontSize: 24
  }

  const showHiddenLink = {
    alignSelf: 'flex-end',
    paddingBottom: '0.65rem',
    paddingLeft: '0.7rem',
    fontSize: '0.8rem',
  }

  const leftSideInfo = {
    alignItems: 'center',
    paddingLeft: '0.6rem',
    width: '60%',
  }


  const handleClick = ({ showHidden }) => {
    const data = table.state();

    saveTableSettings(data);
    setShowHidden(showHidden);
  }

  function ShowHiddenLink(props) {
    return <Buttons.Link text="Visible" style={showHiddenLink} onClick={() => handleClick({showHidden: false})} />
  }

  function ShowVisibleLink(props) {
    return <Buttons.Link text="Show Hidden" style={showHiddenLink} onClick={() => handleClick({showHidden: true})} />
  }


  function DisplayLink(props) {
    const { showHidden } = props;

    if (showHidden) {
      return <ShowHiddenLink {...props} />
    }
    return <ShowVisibleLink {...props} />
  }

  // Modal Create
  const handleModal = () => {
    handleShowModal(true);
  }

  const handleToggle = () => {
    toggleData();
  }

  return (
    <>
      <div className="data-table-header">
        <div style={leftSideInfo}>
          <div style={Typography.pageTitle}>{pageTitle}</div>
          {!unhideable &&
            <DisplayLink showHidden={showHidden} />
          }
        </div>
        <div className='hide-column-actions'></div>
         { !uncreatable && !handleShowModal &&
             <Link
              to={createRoute}
              resource="User"
              action="read"
              style={addRecord}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
              { textForCreate && <span style={textForCreateStyle}>{textForCreate}</span> }
            </Link>
          }
          { !uncreatable && handleShowModal && <FontAwesomeIcon
             className='show-modal-button'
             style={addRecord}
             onClick={handleModal}
             icon={faPlusCircle}
           />
         }
      </div>
      <div style={Spacing.tableWrapper}>
        {subHeader &&
          <div style={Typography.formSubHeader}>{subHeader}</div>
        }
        {
         toggleData && <div>
           <input
              type="checkbox"
              checked={toggleDataState}
              onClick={handleToggle}
              style={{marginRight: '10px'}}
            />
            {toggleDataLabel}
         </div>
        }
        <table id={divId} className="cell-border table display hover stripe responsive" width="100%"></table>
      </div>
    </>

  );
}

export default DataTable;
