import { gql } from '@apollo/client';

export const DEALER_REPORT = gql`
  query DealerReport(
    $type: String!
    $startOn: ISO8601Date
    $endOn: ISO8601Date
    $id: ID
  ) {
    dealerReportData(
      type: $type
      startOn: $startOn
      endOn: $endOn
      id: $id
    ) {
      summary
      headers
      entries {
        items {
          value
          style
        }
        height
      }
    }
  }
`;

export const FLOORED_CAR_VINS = gql`
  query NoUserLogFlooredCarVins {
    currentDealer {
      flooredCars(scopes: ["notVoided", "byVin"]) {
        id
        vin
      }
    }
  }
`;
