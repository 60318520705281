import React, { useContext, useState, useEffect } from 'react';
import { useAlert } from '../../hooks/useAlert';
import DataTable from '../../elements/DataTable';
import AuthContext from '../../contexts/AuthContext';
import { Helmet } from 'react-helmet';
import DataState from '../DataState';
import BasicAlert from '../../elements/BasicAlert';
import { setAlertData } from '../../utils/setAlertData';
import { FLOORED_CARS } from '../../queries/indexQueries'
import { useHideableResource } from '../../hooks/useHideableResource';
import { useQuery, useLazyQuery } from '@apollo/client';
import * as StringHelper from '../../utils/stringHelpers';
import * as DateHelpers from '../../utils/dateHelpers';
import DealerInfo from '../DealerInfo';
import { getColumnIndex } from '../../utils/datatableHelpers';

const Cars = (props) => {
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();
  const { loading, error, data } = useQuery(FLOORED_CARS);

  const auth = useContext(AuthContext);
  const componentType = props.location.state?.componentType;

  useEffect(() => setAlertData(props, setAlertType, displayAlert), [])

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const dealerName = data.currentDealer.name;
  const dataSet = data.currentDealer.flooredCars.map(function (car) {
    car = { ...car };

    car.actions = `
      <a href="/cars/${car.id}"/>View</a>
    `

    const { currentFees, currentInterest, currentPrincipal, currentCollateralCoverage } = car;

    const make = StringHelper.titleCase(car.make) || '';
    const model = StringHelper.titleCase(car.model) || '';
    const color = StringHelper.titleCase(car.color) || '';
    const age = car.termData?.totalDays || '';
    const nextDueDate = DateHelpers.setDate(car.currentDueOn) || '';
    const loanDate = DateHelpers.setDate(car.loanDate) || '';

    const totalDue = (
      parseFloat(currentFees) + parseFloat(currentInterest) + parseFloat(currentPrincipal) +
      parseFloat(currentCollateralCoverage)
    )
    const vinLink = `<a href="/cars/${car.id}">${car.vin}</a>`

    return [
      car.unitNumber,
      vinLink,
      car.year,
      make,
      model,
      color,
      age,
      loanDate,
      nextDueDate,
      StringHelper.currency(currentPrincipal),
      StringHelper.currency(currentFees),
      StringHelper.currency(currentInterest),
      StringHelper.currency(currentCollateralCoverage),
      StringHelper.currency(totalDue),
      StringHelper.titleCase(car.titleStatus) || '',
      car.actions
    ]
  })

  const columns = [
    {
      title: 'Unit #'
    },
    {
      title: 'VIN'
    },
    {
      title: 'Year',
      render: DateHelpers.renderDate,
    },
    {
      title: 'Make'
    },
    {
      title: 'Model'
    },
    {
      title: 'Color'
    },
    {
      title: 'Age (days)',
      className: 'text-right'
    },
    {
      title: 'Loan Date',
      render: DateHelpers.renderDate
    },
    {
      title: 'Next Due Date',
      render: DateHelpers.renderDate
    },
    {
      title: 'Principal',
      className: 'text-right'
    },
    {
      title: 'Fees Due',
      className: 'text-right'
    },
    {
      title: 'Interest Due',
      className: 'text-right'
    },
    {
      title: 'Collateral Coverage',
      className: 'text-right'
    },
    {
      title: 'Total Due',
      className: 'text-right'
    },
    {
      title: 'Title Status',
    },
    {
      title: 'Functions'
    },
  ];

  return (
    <>
      <Helmet>
        <title>Cars</title>
      </Helmet>
      {showAlert &&
        <BasicAlert componentType={componentType} type={alertType} />
      }
      <DealerInfo />
      <DataTable
        dataSet={dataSet}
        columns={columns}
        unhideable={true}
        uncreatable={true}
        pageTitle={`Cars for ${StringHelper.titleCase(dealerName)}`}
        order={[getColumnIndex(columns, 'Next Due Date'), 'asc']}
      />
    </>
  )
};

export default Cars;
