import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import { QUOTE_AMOUNTS } from '../../queries/viewQueries';
import { StyledErrorMessage } from '../../elements/forms';

//** Control query requests **//
let loadingQueryIndexes = [];
// Control principal amounts, allow for user to change princpal amounts
let previousPrincipals = [];
// ** //
export const getQuoteData = (car, index, reordered) => {
  const [getQuote, { loading, error, data: quoteData }] = useLazyQuery(QUOTE_AMOUNTS);
  const { values, setFieldValue } = useFormikContext();
  let paymentType = null;
  let newPaymentType;

  if (values.quoteCarsAttributes && values.quoteCarsAttributes[index]) {
   newPaymentType = values.quoteCarsAttributes[index]['paymentType'];
  }

  if (newPaymentType && newPaymentType !== paymentType) {
    paymentType = newPaymentType;
  }

  useEffect(() => {
    getQuote({ variables:
       {
         paymentType,
         flooredCarId: parseInt(car.id),
         date: values.paymentDate,
       }
     })

    loadingQueryIndexes[index] = true;

  }, [values.paymentDate, paymentType, reordered])

  if (loading || !quoteData) return null;
  if (error) return null;

  const { quoteAmounts } = quoteData;

  if (!previousPrincipals[index]) previousPrincipals[index] = quoteAmounts.principal

  // set static values on first load
  if (!values.quoteCarsAttributes || !values.quoteCarsAttributes[index]) {
    const totalDue = parseInt(quoteAmounts.principal) + parseInt(quoteAmounts.fees) +
    parseInt(quoteAmounts.interest) + parseInt(quoteAmounts.insurance) + parseInt(quoteAmounts.reserves);

    setFieldValue(`quoteCarsAttributes[${index}]`,
      {
        totalDue,
        id: index,
        principal: quoteAmounts.principal,
        paymentType: '',
        flooredCarId: parseInt(car.id),
        interest: parseFloat(quoteAmounts.interest),
        insurance: parseFloat(quoteAmounts.insurance),
        reserves: parseFloat(quoteAmounts.reserves),
      }
    )

    const dealerFees = car.dealer.payableFees.map((fee) => {
      const feeId = parseInt(fee.id);
      const amount = parseFloat(fee.due)

      return { feeId, amount }
    })

    setFieldValue(`quoteFeesAttributes`,
      dealerFees
    )

    setFieldValue(`maxPrincipal[${index}]`, parseFloat(quoteAmounts.principal) )
  }

  // set dynamic values after first load
  if (values.quoteCarsAttributes && values.quoteCarsAttributes[index] &&
    loadingQueryIndexes[index] === true) {
    if (values.quoteCarsAttributes[index].reserves !== parseFloat(quoteAmounts.reserves)) {
      let reserves = (!quoteAmounts.reserves)
        ? 0
        : parseFloat(quoteAmounts.reserves)
      values.quoteCarsAttributes[index].reserves = reserves;
    }

    // I have no idea why this works, but it keeps the correct values and fees in order
    values.quoteCarsAttributes[index].feeDetails = [];
    values.quoteCarsAttributes[index].insurance = parseFloat(quoteAmounts.insurance);
    values.quoteCarsAttributes[index].interest = parseFloat(quoteAmounts.interest);
    // ** //

    // Keep this it must be present for principal values to update on re-queries
    if (parseFloat(previousPrincipals[index]) !== parseFloat(quoteAmounts.principal)) {
      previousPrincipals[index] = parseFloat(quoteAmounts.principal);
      setFieldValue(`quoteCarsAttributes[${index}].principal`, quoteAmounts.principal);
      values.maxPrincipal[index] = parseFloat(quoteAmounts.principal);
      loadingQueryIndexes[index] = false;
      previousPrincipals[index] = parseFloat(quoteAmounts.principal);
    }
  }

 if (values.quoteCarsAttributes && values.quoteCarsAttributes[index] && !values.quoteCarsAttributes[index]['feeDetails']) {
  values.quoteCarsAttributes[index]['feeDetails'] = [];
 }

 return quoteData
}

export const formatValues = (values, types) => {
  types.forEach((type) => {
    values.map((value, index) => {
      delete value.id;
      delete value.totalDue;

      if (type === 'principal' && value[type] === '') value[type] = 0;

      if (value.paymentType === 'principal') {
        value.insurance = 0
        value.interest = 0
      }

      if (type === 'principal' && value['principal'] !== 0 ) {
        value['principal'] = parseFloat(value['principal']);
      }

    })
  })

  values.map((value, index) => {
    value['quoteFeesAttributes'] = [];
      value.feeDetails.forEach((fee) => {
        const id = Object.keys(fee)[0];
        if (fee[id].amount || fee[id].amount == 0) {
          value['quoteFeesAttributes'].push(
            { feeId: parseInt(id),
              amount: fee[id].amount,
            }
          )
        }
      })

    delete value['feeDetails'];
  })

  return values
}

export const PrincipalError = ({values, index, disabledArray}) => {
  const currentPrincipal = parseFloat(values.quoteCarsAttributes[index].principal);
  const maxPrincipal = parseFloat(values.maxPrincipal[index]);

  if (!currentPrincipal || currentPrincipal <= maxPrincipal) {
    disabledArray[index] = false;
  }

  if (currentPrincipal > maxPrincipal) {
    disabledArray[index] = true;

    return <StyledErrorMessage style={{paddingLeft: '15px', fontSize: '14px', width: '57%', marginRight: '-150px'}}>
      Can't be more than the Principal amount owed.
    </StyledErrorMessage>
  }

  return null;
}
