import { useEffect, useRef } from 'react';
import {createBrowserHistory } from 'history';

export const setAlertData = (props, setAlertType, displayAlert) => {
  const history = createBrowserHistory();

  if (props && props.location && props.location.state && props.location.state.alert) {

    if (props.location.state.type) setAlertType(props.location.state.type)

    displayAlert();
    let state = {...history.location.state};
    delete state.alert
    delete state.type
    history.replace({...history.location, state})
  }


  return undefined;
}

// This is for unique situations where multiple creations can happen on one page
// EXAMPLE =>
// On auctions/notes, notes can be created and so can a sale time.
// This function will only show the alert that passes a componentType value when redirecting
// In this case, sale time is the nested component, so we will pass the componentType
// on sale time create.

export const setUniqueAlertData = (props, setAlertType, displayAlert) => {
  if (!props.location || !props.location.state) return null;

  if (props.location.state && props.location.state.componentType) {
    return props;
  } else {
    useEffect(() => setAlertData(props, setAlertType, displayAlert), [])
    return null;
  }
}

// After refactoring for this modal, we can probably make the setAlertData function
// this simple.
export const setAlertFromModal= (props, setAlertType, displayAlert) => {
  if (props && props.location && props.location.state && props.location.state.alert) {

    if (props.location.state.type) setAlertType(props.location.state.type)

    displayAlert();
    delete props.location.state
    // window.location.reload()
  }

  return undefined;
}
