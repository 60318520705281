import * as yup from 'yup';

export const schema = yup.object({
  advanceType: yup
    .string()
    .required('Required'),
  bankAccountId: yup
    .string()
    .when('advanceType',
      (advanceType, schema) =>
        (advanceType === 'source')
          ? schema.required('Required')
          : schema
        ),
  accountId: yup
    .string()
    .when('advanceType',
      (advanceType, schema) =>
        (advanceType === 'external')
          ? schema.nullable().required('Required')
          : schema
        ),
  txnType: yup
    .string()
    .when('advanceType',
      (advanceType, schema) =>
        (advanceType === 'external')
          ? schema.required('Required')
          : schema
      ),
  total: yup
    .number()
    .when('advanceType',
      (advanceType, schema) =>
      (advanceType === 'external')
      ? schema.required('Required')
      : schema
    ),
})
