import React, { useState, useRef, useEffect} from 'react';
import { useParams, Redirect, useRouteMatch } from 'react-router-dom';
import { Spacing } from '../../styles';
import { Container, Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import {
  Image,
  InfoTable,
  labels,
  getItems,
  setValues,
  getSourceQuery
} from './functions';
import * as Buttons from '../../elements/buttons';
import DataState from '../DataState';
import { useQuery, useLazyQuery } from '@apollo/client';
import { FLOORED_CAR } from '../../queries/viewQueries';
import * as Colors from '../../styles/colors';
import { setAlertData } from '../../utils/setAlertData';
import { useAlert } from '../../hooks/useAlert';
import BasicAlert, { RepoAlert, AchAlert } from '../../elements/BasicAlert';
import DataTable from '../../elements/DataTable';
import * as StringHelper from '../../utils/stringHelpers';
import * as DateHelper from '../../utils/dateHelpers';
import './car-show.css';
import Receipts from '../Receipts';
import { containerStyle, hrStyle } from './style';

let source;
let query;

const ShowCar = (props) => {
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();
  const [redirect, setRedirect] = useState(false);
  const [show, setShow] = useState(false);

  const dealerId = parseInt(useParams().id);
  const id = parseInt(useParams().carId);
  const route = useRouteMatch();

  const handleClose = () => setShow(false);

  const [getSourceType, {
    loading: loadingSource,
    error: errorSource,
    data: dataSource
  }] = useLazyQuery(query)

  const { loading, error, data } = useQuery(FLOORED_CAR, { variables: { id }})

  if ((loading && !data) ||
    (loadingSource && !dataSource)) return <DataState.Loading />
  if (error || errorSource) return <DataState.Error error={error} />;

  const flooredCar = { ...data.flooredCar }

  if ( flooredCar && flooredCar.sourceType && query && !source) {
    getSourceType({variables: { id: flooredCar.sourceId }});
    source = dataSource;
  }

  if (dataSource) flooredCar.sourceName = (Object.values(dataSource)[0].name)

  const values = setValues(flooredCar)

  const Car = () => {
    return (
      <>
        <Container style={Spacing.showContainer}>
          <Row>
            <Col style={{display: 'flex', flexDirection: 'column'}}xl={2}>
              <Image />
            </Col>
            <Col xl={2}>
              <InfoTable
                labels={getItems(labels, 0, 6)}
                values={getItems(values, 0, 6)}
              />
            </Col>
            <Col xl={4}>
              <InfoTable
                labels={getItems(labels, 6, 12)}
                values={getItems(values, 6, 12)}
              />
            </Col>
            <Col xl={4}>
            <InfoTable
              labels={getItems(labels, 12)}
              values={getItems(values, 12)}
            />
            </Col>
          </Row>
        </Container>
      </>
    )
  }

  const FeesPaid = () => {
    const feeColumns = [
      {
        title: 'Fee'
      },
      {
        title: 'Amount'
      },
      {
        title: 'Assesed On'
      },
      {
        title: 'Paid?'
      },
    ];

    const feesDataSet = flooredCar.fees.map((fee) => {
      return [
        fee.name,
        StringHelper.currency(fee.amount),
        DateHelper.setDate(fee.chargeOn),
        fee.paid
      ]
    })

    return (
      <>
        <Container style={Spacing.showContainer}>
          <Row>
            <Col xl={4}>
              <DataTable
                dataSet={feesDataSet}
                columns={feeColumns}
                pageTitle={`Fees`}
                unhideable={true}
                uncreatable={true}
              />
            </Col>
            <Col xl={4}>

            </Col>
            <Col xl={4}>

            </Col>
          </Row>
        </Container>
      </>
    )
  };

  if (redirect) {
    return <Redirect
      to={{
        pathname: `/dealers/${dealerId}/cars`
      }}
    />
  }

  return (
    <>
      { flooredCar.repoLocation &&
        <RepoAlert location={flooredCar.repoLocation}/>
      }
      { showAlert &&
        <BasicAlert componentType="title log" type={alertType} />
      }
      {
        flooredCar.scheduledAchs > 0 &&
        <AchAlert
          date={flooredCar.scheduledAchDate}
          amount={flooredCar.scheduledAchAmount}
          carAmount={flooredCar.scheduledAchCarAmount}
          type={flooredCar.scheduledAchType}
        />
      }
      <div style={Spacing.formContainer}>
      {
        route.isExact &&
        <div className="container" style={containerStyle}>
          <Car />

          <hr style={hrStyle}/>

          <hr style={hrStyle}/>
          <FeesPaid />

          <hr style={hrStyle}/>
          <Receipts flooredCar={flooredCar} />
        </div>
      }
      </div>
    </>
  );
};

export default ShowCar;
