import React, { useState } from "react";
import { useRouteMatch, useParams, Redirect } from "react-router-dom";
import { Form } from "./Form";
import { schema } from './Schema';
import { gql, useMutation, useQuery } from "@apollo/client";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import { useFormikContext } from "formik";
import * as Buttons from "../../../elements/buttons";
import { Spacing } from "../../../styles";
import DataState from "../../DataState";
import { DEALER } from '../../../queries/viewQueries';
import * as DateHelper from '../../../utils/dateHelpers';
import * as StringHelper from '../../../utils/stringHelpers';
import { AUTH_TOKEN } from '../../../constants';
import secrets from '../../../secrets';
import { Dots } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import { PAY_QUOTE, ACH_QUOTE } from './Queries';
import { modalBodyStyle, receiptButtons, dotsStyle } from './Style';

const NewPayment = props => {
  const {
    propAlertData,
    showModal,
    setShowModal,
    dealerName,
    reserveBalance,
    quoteAmount,
    quoteDate,
    quoteId,
  } = props;

  const dealerId = parseInt(useParams().id);
  const [redirect, setRedirect] = useState(false);
  const [readyForReceipt, setReadyForReceipt] = useState(false);
  const [receiptHandled, setReceiptHandled] = useState(false);
  const [receipt, setReceipt] = useState(null);
  const [quoteStatus, setQuoteStatus] = useState(null);
  const [apiCalled, setApiCalled] = useState(false);
  const { loading, error, data } = useQuery(DEALER)

  const [payQuote, { loading: quoteLoading, error: quoteError }] = useMutation(
    PAY_QUOTE, {
      onCompleted(values) {
        setQuoteStatus(values['achQuote']['quote']['status']);
        setReadyForReceipt(true);
        setRedirect(true);
      },
    }
  );

  const [achQuote, { loading: achLoading, error: achError }] = useMutation(
    ACH_QUOTE,
    {
      onCompleted(values) {
        setQuoteStatus(values['achQuote']['quote']['status']);
        setReadyForReceipt(values.achQuote.quote.id);
        setRedirect(true);
      },
    }
  );

  const handlePrint = () => {
    var url = URL.createObjectURL(receipt);
    window.open(url,'_blank');
    setReceiptHandled(true);
  }

  const handleSave = () => {
    var date = new Date();
    var timestamp = date.getTime();
    const FileDownload = require('js-file-download');
    FileDownload(receipt, `Payment_Receipt_${timestamp}.pdf`);
    setReceiptHandled(true);
  }

  const handleCancel = () => {
    setReceiptHandled(true);
  }

  const handleSubmit = (values) => {
    if (values.advanceType !== "source") delete values.bankAccountId;
    if (values.name === "") values.name = null;
    if (values.reference === "") values.reference = null;
    if (values.advanceType === "On Account") {
      values.txnType = "On Account";
    }
    if (values.advanceType === "Dealer Reserve") {
      values.txnType = "Dealer Reserve";
    }
    if (values.accountId === "") values.accountId = null;

    if (values.advanceType === "source") {
      delete values.advanceType;
      delete values.total;
      values.bankAccountId = parseInt(values.bankAccountId);
      achQuote({ variables: values });
    } else {
      delete values.advanceType, payQuote({ variables: values });
    }
  };

  if (quoteLoading || achLoading || loading) return <DataState.Loading />;
  if (quoteError || achError || error)
    return <DataState.Error error={quoteError || achError || error} />;

  let total = 0;
  const sum = data.currentDealer.payableFees.map((num) => {
    total += num.due
  })

  if (redirect && receiptHandled) {
    return (
      <Redirect
        to={{
          pathname: `/cars`,
          state: { alert: true, type: "processed", componentType: "payment" },
        }}
      />
    );
  } else if (redirect && readyForReceipt && !receiptHandled) {
    if(quoteStatus !== 'pending' && quoteStatus !== 'expired') {
      if(!apiCalled) {
        const axios = require('axios');
        const token = localStorage.getItem(AUTH_TOKEN);
        setApiCalled(true);
        axios({
          method: 'get',
          url: `${secrets.api.baseUri}/print-receipt`,
          responseType: 'blob',
          headers:  {
            'Content-Type' : 'application/json',
            'Accept' : 'application/pdf',
            'Authorization' : `${token}`,
            'JWT_AUD' : 'react'
          },
          params: {
            'quote-id': readyForReceipt,
            'portal-payment': true
          }
        })
        .then(res => {
          setReceipt(res.data);
        })
      }
      return (
        <Formik
          initialValues={{
            id: quoteId,
            advanceType: "source",
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          <PrintReceiptModal
            showModal={showModal}
            setShowModal={setShowModal}
            dealerName={dealerName}
            handleSubmit={handleSubmit}
            quoteAmount={quoteAmount}
            handleSave={handleSave}
            handlePrint={handlePrint}
            handleCancel={handleCancel}
            receipt={receipt}
          />
        </Formik>
      );
    } else {
      setReceiptHandled(true);
    }
  }

  return (
    <Formik
      initialValues={{
        id: quoteId,
        advanceType: "source",
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <ProcessPaymentModal
        showModal={showModal}
        setShowModal={setShowModal}
        dealerName={dealerName}
        reserveBalance={reserveBalance}
        handleSubmit={handleSubmit}
        quoteAmount={quoteAmount}
        quoteDate={quoteDate}
        total={total}
      />
    </Formik>
  );
};

const ProcessPaymentModal = props => {
  const {
    showModal,
    setShowModal,
    dealerName,
    reserveBalance,
    handleSubmit,
    quoteAmount,
    quoteDate,
    total
  } = props

  const { values, errors } = useFormikContext();
  const [disabled, setDisabled] = useState(false);

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        onHide={() => setShowModal(false)}
        show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Payment Information | Total: {StringHelper.currency(quoteAmount)
            } | Payment Date: {DateHelper.setDate(quoteDate)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalBodyStyle}>
          <p>
            Payment: {StringHelper.currency(quoteAmount - total)} | Dealer Fees
            : {StringHelper.currency(total)}
          </p>
          <Form
            dealerName={dealerName}
            reserveBalance={reserveBalance}
            quoteAmount={quoteAmount}
            setDisabled={setDisabled}
          />
          <div style={Spacing.buttonRow}>
            <Buttons.ModalCancel
              text="Cancel"
              handleClose={() => setShowModal(false)}
            />
            <Buttons.Standard
              type="submit"
              onClick={() => handleSubmit(values)}
              text="Submit"
              disabled={disabled}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const PrintReceiptModal = props => {
  const {
    showModal,
    setShowModal,
    dealerName,
    handleSubmit,
    quoteAmount,
    handleSave,
    handlePrint,
    handleCancel,
    receipt
  } = props;

  const { values, errors } = useFormikContext();
  const [disabled, setDisabled] = useState(false);

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        onHide={handleCancel}
        show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Print Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalBodyStyle}>
          <div style={dotsStyle}>
            {receipt === null && <Dots size={50} color={'#2b6448'} />}
          </div>
          <div style={receiptButtons}>
            <Buttons.Standard
              type="submit"
              onClick={() => handleSave()}
              text="Save"
              disabled={receipt === null}
            />
            <Buttons.Standard
              type="submit"
              onClick={() => handlePrint()}
              text="Print"
              disabled={receipt === null}
            />
            <Buttons.Cancel
              type="submit"
              text="Continue"
              onClick={handleCancel}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewPayment;
