import React, { useState, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import * as Formatting from '../styles/formatting';
import * as Buttons from '../elements/buttons';
import { titleCase, currency } from '../utils/stringHelpers';
import DataState from './DataState';
import { DEALER } from '../queries/viewQueries';
import AuthContext from '../contexts/AuthContext';
import './dealershow.css';
import * as DateHelpers from '../utils/dateHelpers';
import NewFundRequest from './Funds/Modal';
import {
  greenText,
  boldText,
  bottomBorder,
  imageContainer,
  rowStyle,
  buttonRow,
  expiresOn
} from './style';

export const UPDATE_DEALER_AUTOPAY = gql`
  mutation UpdateDealerAutopay($id: ID!, $autopay: Boolean!) {
    updateDealerAutopay(id: $id, autopay: $autopay) {
      dealer { id }
      errors { path, message }
    }
  }
`;

const tempPrograms = ({ dealer, type }) => {
  return dealer.tempPrograms.filter(program => {
    if ('CreditLimit' === type) {
      return program.creditLimitIncrease;
    } else {
      return program.programType === type;
    }
  });
};

const DealerInfo = (props) => {
  const { loading, error, data, refetch } = useQuery(DEALER);
  const [updateDealerAutopay] =
    useMutation(UPDATE_DEALER_AUTOPAY, { onCompleted: refetch });
  const [showModal, setShowModal] = useState(false);
  const auth = useContext(AuthContext);
  const handleClose = () => setShowModal(false);

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const {
    accountNumber,
    creditLine,
    availableCredit,
    currentPrincipal,
    autopay,
  } = data.currentDealer;

  const dealer = data.currentDealer;

  const branchName = data.currentDealer.branch.name;
  const marketName = data.currentDealer.market.name;
  const status = data.currentDealer.dealerStatus.name;
  let displayStatus;
  if (status === 'Active') {
    displayStatus = 'Active'
  } else {
    displayStatus = 'On Hold'
  }
  const accountRep = `${data.currentDealer.accountRep?.firstName || ''} ${data.currentDealer.accountRep?.lastName || ''}`;
  const phone = `${data.currentDealer.accountRep?.mobilePhone.formatted || ''}`;
  const email = `${data.currentDealer.accountRep?.email || ''}`;
  const rateProgram =
    data.currentDealer.dealerLinesAttributes[0].rateProgram?.name;
  const dealerProgram =
    data.currentDealer.dealerLinesAttributes[0].dealerProgram?.name;
  const onAccountBalance = data.currentDealer.onAccountBalance
  const reserveBalance = data.currentDealer.reserveBalance

  const tempCreditIncreases = tempPrograms({ dealer, type: 'CreditLimit' });
  const tempDealerPrograms = tempPrograms({ dealer, type: 'DealerProgram' });
  const tempRatePrograms = tempPrograms({ dealer, type: 'RateProgram' });

  const total = tempCreditIncreases
    .map(tempProgram => parseFloat(tempProgram.creditLimitIncrease))
    .reduce((sum, increase) => (sum + increase), parseFloat(dealer.creditLine));

  const updateAutopay = enable => {
    const confirmation = enable ? "Really enable AutoPay?" :
      "Really cancel AutoPay";

    if (confirm(confirmation)) {
      updateDealerAutopay(
        { variables: { id: data.currentDealer.id, autopay: enable } }
      );
    }
  };

  return (
    <>
      <Container style={Formatting.infoContainer}>
        <Row style={rowStyle}>
          <div className="col-xl-3 info-section">
            <Row className="info-row">
              <Col>Account Status:</Col>
              <Col align="right">{displayStatus}</Col>
            </Row>
            <Row className="info-row">
              <Col>Account Number:</Col>
              <Col align="right">{accountNumber}</Col>
            </Row>
            <Row className="info-row">
              <Col>Credit Limit:</Col>
              <Col xs={8} align="right">
                {currency(data.currentDealer.creditLine)}
                <br />
                {tempCreditIncreases.map(tempProgram => (
                  <div>
                    Temp (Expires {DateHelpers.setDate(tempProgram.endOn)}):
                    {currency(parseInt(tempProgram.creditLimitIncrease))}
                  </div>
                ))}
                Total: {currency(total)}
              </Col>
            </Row>
            <Row className="info-row">
              <Col>Current Principal Balance:</Col>
              <Col align="right">{currency(currentPrincipal)}</Col>
            </Row>
            <Row className="info-row">
              <Col>Current Availability</Col>
              <Col align="right">{currency(availableCredit)}</Col>
            </Row>
            <Row className="info-row">
              <Col>AutoPay</Col>
              <Col align="right">
                AutoPay {autopay ? 'Enabled' : 'Disabled'}
                {auth.hasPolicy('Dealer', 'autopay') && (
                  <span>
                    {' ('}
                      <a href="#" onClick={() => updateAutopay(!autopay)}>
                       {autopay ? 'Cancel' : 'Enable'}
                     </a>
                    )
                  </span>
                )}
              </Col>
            </Row>
          </div>
          <div className="col-xl-5 info-section">
            <Row className="info-row">
              <Col>Dealer Programs</Col>
              <Col xs={8} align="right">
                {dealerProgram}
                {tempDealerPrograms.map(tempProgram => (
                  <div>
                    Temp (Expires {DateHelpers.setDate(tempProgram.endOn)}):
                    <br />
                    {tempProgram.programName}
                  </div>
                ))}
              </Col>
            </Row>
            <Row className="info-row">
              <Col>Rate Program</Col>
              <Col align="right">
                {titleCase(rateProgram)}
                {tempRatePrograms.map(tempProgram => (
                  <div>
                    Temp (Expires {DateHelpers.setDate(tempProgram.endOn)}):
                    {' '}{tempProgram.programName}
                  </div>
                ))}
              </Col>
            </Row>
            <Row className="info-row">
              <Col>Reserve Balance</Col>
              <Col align="right">{currency(reserveBalance)}</Col>
            </Row>
            <Row className="info-row">
              <Col>On Account Balance</Col>
              <Col align="right">{currency(onAccountBalance)}</Col>
            </Row>
          </div>
          <div className="col-xl-3 info-section">
            <Row className="info-row">
              <Col>Market</Col>
              <Col align="right">{marketName}</Col>
            </Row>
            <Row className="info-row">
              <Col>Branch</Col>
              <Col align="right">{titleCase(branchName)}</Col>
            </Row>
            <Row className="info-row">
              <Col>Account Manager</Col>
              <Col align="right">{accountRep}</Col>
            </Row>
            <Row className="info-row">
              <Col>Email</Col>
              <Col align="right">{email}</Col>
            </Row>
            <Row className="info-row">
              <Col>Phone</Col>
              <Col align="right">{phone}</Col>
            </Row>
          </div>
        </Row>
      </Container>
      {auth.hasPolicy('DealerTxn', 'create') &&
        <div style={buttonRow}>
          <Buttons.Link style={{ width: '100%' }}
            text="Request Use of Funds"
            onClick={() => setShowModal(true)}
          />
        </div>
      }
      <div style={bottomBorder} />
      {
        showModal && <NewFundRequest
          showModal={showModal}
          setShowModal={setShowModal}
          dealerId={data.currentDealer.id}
        />
      }
    </>
  )
}

export default DealerInfo;
