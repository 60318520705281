import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import * as Buttons from 'components/src/elements/buttons';
import { Spacing } from 'components/src/styles';
import moment from 'moment';
import { FLOORED_CAR_VINS } from './queries';
import Select from 'react-select';
import DataState from '../DataState';

const dateStyle = ({
  marginLeft: 5,
  marginRight: 10,
  minWidth: '7em',
  minHeigt: '2.5em',
  height: '2.5em',
  '-webkit-appearance': 'none',
  '-moz-appearance': 'none',
});

const OptionButtons = ({ handleSubmit, handleCancel, isValid }) => {
  return (
    <div style={Spacing.buttonRow}>
      <Buttons.ModalCancel
        text="Cancel"
        handleClose={handleCancel}
      />

      <Buttons.Standard
        type="submit"
        onClick={handleSubmit}
        text="Submit"
        disabled={!isValid}
      />
    </div>
  );
};

export const ChooseDates = ({ report, handleCancel }) => {
  const [startOn, setStartOn] = useState('');
  const [endOn, setEndOn] = useState('');
  const history = useHistory();

  const isValid = startOn && moment(startOn).isValid() &&
    endOn && moment(endOn).isValid();

  const handleSubmit = () => {
    history.push(`/report/${report}/${startOn}/${endOn}`);
    handleCancel();
  }

  return (
    <>
      <h2 style={{fontSize: '1.3em'}}>
        {report} Report
      </h2>

      <label>
        Start Date
        <input
          type="date"
          value={startOn}
          onChange={e => setStartOn(e.target.value)}
          style={dateStyle}
        />
      </label>

      <label>
        End Date
        <input
          type="date"
          value={endOn}
          onChange={e => setEndOn(e.target.value)}
          style={dateStyle}
        />
      </label>

      <OptionButtons
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        isValid={isValid}
      />
    </>
  );
};

export const ChooseVIN = ({ report, handleCancel }) => {
  const [id, setId] = useState();
  const history = useHistory();

  const isValid = !!id;

  const handleSubmit = () => {
    history.push(`/report/${report}/${id}`);
    handleCancel();
  }

  const { loading, error, data } = useQuery(FLOORED_CAR_VINS);

  if (loading && !data) return <DataState.Loading />;
  if (error) return <DataState.Error error={error} />;

  const options = data.currentDealer.flooredCars.map(car => (
    { value: car.id, label: car.vin.toUpperCase() }
  ));

  return (
    <>
      <h2 style={{fontSize: '1.3em'}}>
        Payment Report&mdash;Single Car
      </h2>

      <label>Choose VIN</label>
      <Select
        options={options}
        onChange={selected => setId(selected?.value)}
      />

      <OptionButtons
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        isValid={isValid}
      />
    </>
  );
};
