import { gql } from '@apollo/client';

export const CURRENT_USER = gql`
  query {
    currentUser {
      id
      firstName
      lastName
      mobilePhone {
        formatted
      }
      userProvidedPhoneNumber {
        formatted
      }
      notificationsEmail
      notificationsSms
    }
    currentDealer{
      id
      name
    }
  }
`;


