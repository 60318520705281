import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form as FormikForm } from 'formik';
import * as yup from "yup";
import { Spacing } from '../styles';
import * as Buttons from '../elements/buttons';
import * as BasicForm from "../elements/forms";
import secrets from '../secrets';
import { equalTo } from '../utils/passwordMatchValidation';
import { StringHelpers } from '../utils/';
import { AUTH_TOKEN } from '../constants';

yup.addMethod(yup.string, 'equalTo', equalTo);

const generateCodeSchema = yup.object({
  email: yup.string().email("Please enter a valid email address"),
  newPassword: yup
    .string()
    .min(8, 'Password must have 8 or more characters')
    .matches(StringHelpers.specialCharacterRegExp, { message: "Password must contain at least one special character" }),
  passwordConfirmation: yup
    .string()
    .equalTo(yup.ref('newPassword'), 'Passwords must match'),

})

const ForgottenPasswordModal = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [codeGenerated, setCodeGenerated] = useState(false);

  const handleSubmit = (values, actions, event) => {
    setFormSubmitting(true)
    const email = values.email
    const token = values.token
    const new_password = values.newPassword
    setCodeGenerated(true)
    const path = codeGenerated ? '/passwords/reset' : '/passwords/forgot'
    const body = codeGenerated ?
      JSON.stringify({ dealer_user: { email, token, new_password } }) :
      JSON.stringify({ dealer_user: { email } })
    let options = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'JWT_AUD': secrets.api.jwtAud
      },
      body: body
    };

    fetch(secrets.api.baseUri + path, options)
      .then(response => {
        if (!response.ok) {
          alert('Email or Reset Code is incorrect - please try again.');
          setFormSubmitting(false);
        } else {
          setFormSubmitting(false);
          if (codeGenerated) {
            response.json().then(data => {
              let options = {
                method: 'post',
                headers: {
                  'Content-Type': 'application/json',
                  'JWT_AUD': secrets.api.jwtAud
                },
                body: JSON.stringify({
                  dealer_user: { email: data.email, password: new_password }
                })
              };

              fetch(secrets.api.baseUri + '/dealer_users/sign_in', options)
                .then(response => {
                  if (!response.ok) {
                    alert('Login failed, please retry');
                  } else {
                    localStorage.setItem(AUTH_TOKEN, response.headers.get('authorization'));
                    window.location.assign('/');
                  }

                  return response.json();
                });
            })
          } else {
            return response.json();
          }
        }
      });
  }


  const submitText = codeGenerated ? 'Login' : 'Send Code Via Text'

  return (
    <>
      <div style={Spacing.formWrapper}>
        <Formik
          initialValues={{
            email: ''
          }}
          validationSchema={generateCodeSchema}
          onSubmit={handleSubmit}
        >
          <FormikForm>
            <BasicForm.TextInput
              name="email"
              type="text"
              label="E-Mail"
            />
            {codeGenerated
              ?
              <>
                <BasicForm.TextInput
                  name="token"
                  type="text"
                  label="Password Reset Code (sent via text)"
                />
                <BasicForm.TextInput
                  name="newPassword"
                  type="password"
                  label="Enter New Password"
                />
                <BasicForm.TextInput
                  name="passwordConfirmation"
                  type="password"
                  label="Please Confirm Your Password"
                />
              </>
              : null}

            <div style={Spacing.buttonRow}>
              <Buttons.Cancel type="cancel" text="Cancel" />
              <Buttons.Standard
                type="submit"
                text={submitText}
                disabled={formSubmitting}
              />
            </div>
          </FormikForm>
        </Formik>
      </div>
    </>
  )
}


const ForgotPassword = () => {
  const [showModal, toggleShowModal] = useState(true)

  const handleClose = () => {
    toggleShowModal(false);
    window.location.reload();
  }

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
        show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {'Reset Password'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ForgottenPasswordModal />
        </Modal.Body>
      </Modal>
    </>
  )
};

export default ForgotPassword;
