import { gql } from '@apollo/client';

export const PAY_QUOTE = gql`
  mutation PayQuote(
    $id: ID!
    $txnType: String!
    $name: String
    $reference: String
    $accountId: Int
    $total: Decimal
  ) {
    payQuote(
      id: $id
      name: $name
      txnType: $txnType
      reference: $reference
      accountId: $accountId
      total: $total
    ) {
      quote {
        id
        txns {
          id
          txnLines {
            debit
            credit
            account {
              name
            }
          }
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

export const ACH_QUOTE = gql`
  mutation AchQuote($id: ID!, $bankAccountId: Int) {
    achQuote(id: $id, bankAccountId: $bankAccountId) {
      quote {
        id
        status
      }
    }
  }
`;

export const BANK_ACCOUNTS = gql`
  query CurrentDealer {
    currentDealer {
      id
      primaryBankAccount {
        id
        accountName
        accountNumberMasked
      }
      bankAccounts {
        id
        accountName
        accountNumberMasked
      }
    }
  }
`;

export const ACCOUNTS = gql`
  query {
    accounts(scopes: ["asset"]) {
      id
      name
      gl
      special {
        key
      }
    }
  }
`;
