import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Navbar,
  InputGroup,
  Nav,
  Dropdown,
  NavItem,
  NavLink,
  Form,
  FormControl,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationSquare, faCars, faMoneyCheckEditAlt } from '@fortawesome/pro-solid-svg-icons'
import {
  faHome,
  faLocationArrow,
  faFunnelDollar,
  faCar,
  faGavel,
  faDollarSign,
  faServer,
  faUserCircle,
  faReceipt,
  faFileInvoice,
} from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from 'react-router-bootstrap';
import * as Colors from '../styles/colors';
import AuthLink from './AuthLink';
import logo from '../components/images/logo.png';
import { useQuery } from '@apollo/client';
import './topnavigation.css';
import Routes from '../routes/Routes';
import RequireMobileRoutes from '../routes/RequireMobileRoutes';
import { CURRENT_USER } from '../queries/commonQueries';
import AuthContext from '../contexts/AuthContext';
import DataState from './DataState';
import ApolloClient from 'apollo-client';
import { HttpLink, ApolloLink, InMemoryCache } from 'apollo-boost'
import { ApolloProvider, Query, Mutation } from 'react-apollo'
import gql from 'graphql-tag';
import * as ActionCable from '@rails/actioncable'
import { ActionCableLink } from 'graphql-ruby-client';
import { AUTH_TOKEN, DEALER_PERSON_ID } from '../constants';
import secrets from '../secrets';
import ElasticsearchBox from './ElasticsearchBox';
import Reporting from './Reporting';

const bottomBorder = {
  height: '7px',
  width: '100%',
  backgroundColor: Colors.xlOrange,
}

const logoWrapper = {
  flex: 1,
  alignSelf: 'center',
  backgroundColor: '#f8f9fa',
  paddingTop: '1.8%',
  paddingBottom: '1.8%',
  paddingLeft: '1%'
}

const navOptionsWrapper = {
  display: 'flex',
  flex: 25,
  flexDirection: 'column',
}

const notificationWrapper = {
  flex: 2
}

const menuWrapper = {
  flex: 1,
  marginTop: '-1%'
}

const navBar = {
  paddingTop: 0,
  paddingBottom: 0,
  content: '',
  whiteSpace: 'pre'
}

const navItem = {
  color: Colors.xlOrange,
  fontSize: 17,
  marginRight: 50,
};

const navText = {
  marginLeft: 4,
  fontWeight: 500,
};

const notificationItem = {
  color: Colors.xlGreen,
  fontSize: 12,
};

const cable = ActionCable.createConsumer(
  secrets.api.baseUri + '/cable'
)

const httpLink = new HttpLink({
  uri: secrets.api.baseUri + '/graphql',
  headers: {
    authorization: localStorage.getItem(AUTH_TOKEN),
    jwt_aud: secrets.api.jwtAud
  }
});

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(
    ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription'
  )
}

const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({ cable }),
  httpLink
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

const GET_NOTIFICATIONS_FOR_NAVBAR = gql`
  query($ownerId: Int!, $ownerType: String) {
    notifications(ownerId: $ownerId, ownerType: $ownerType, scopes: ["unviewed"]) {
      id
      notification
      link
      ownerId
      ownerType
    }
  }
`

const SUBSCRIPTION_ADD_NOTIFICATION_TO_NAVBAR = gql`
  subscription ($ownerId: Int!, $ownerType: String) {
    notificationAdded(ownerId: $ownerId, ownerType: $ownerType) {
      id
      ownerId
      ownerType
      notification
      link
    }
  }
`

const NotificationsCount = ({ ownerId, loading, error, data, subscribeToMore }) => {
  useEffect(() => {
    subscribeToMore({
      document: SUBSCRIPTION_ADD_NOTIFICATION_TO_NAVBAR,
      variables: { ownerId: parseInt(ownerId), ownerType: "DealerPerson" },
      updateQuery(prev, { subscriptionData }) {
        return {
          notifications: subscriptionData.data.notificationAdded
        }
      }
    })
  }, [ownerId, "DealerPerson"])
  if (loading || error) return null
  return (data.notifications || []).length
}

const ReportingLink = ({ dealer, setExpanded }) => {
  const [showReportingModal, setShowReportingModal] = useState(false);

  return (
    <>
      <div
        className="nav-link"
        onClick={() => { setShowReportingModal(true); setExpanded(false); }}
        style={{...navItem, cursor: 'pointer'}}
      >
        <FontAwesomeIcon icon={faFileInvoice} />
        <span style={navText}>REPORTING</span>
      </div>

      {showReportingModal && (
        <Reporting
          dealer={dealer}
          handleCancel={() => setShowReportingModal(false)}
        />
      )}
    </>
  );
};

const TopNavigation = () => {
  const { loading, error, data } = useQuery(CURRENT_USER);
  const [mobileMissing, setMobileMissing] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const mobilePhone = data?.currentUser?.userProvidedPhoneNumber?.formatted
  const auth = useContext(AuthContext);

  useEffect(() => {
    mobilePhone == null ? setMobileMissing(true) : setMobileMissing(false)
  }, [mobilePhone])

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const ownerId = data.currentUser.id
  const ownerType = "DealerPerson"
  const dealerName = data.currentDealer.name
  const dealerId = data.currentDealer.id

  const RenderRoutes = () => {
    if (mobileMissing) {
      return <RequireMobileRoutes />
    } return <Routes />
  }

  return (
    <>
      <Navbar
        collapseOnSelect
        expanded={expanded}
        onToggle={setExpanded}
        className="navbar navbar-light"
        expand="lg"
        bg="light"
        style={navBar}
      >
        <LinkContainer to="/cars" style={navItem}>
          <Navbar.Brand className="my-auto" href="#">
            <img src={logo} alt="Logo" height="40" />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-toggler-right" />
        <Navbar.Collapse className="flex-md-column" id="responsive-navbar-nav">
          <InputGroup>
            <Nav className="mr-auto small">
              <Nav.Item>
                <Nav.Link href="/notifications" style={notificationItem}>
                  <FontAwesomeIcon icon={faExclamationSquare} />
                  <ApolloProvider client={client}>
                    <span style={navText}>
                      Notifications (
                        <span style={{ color: '#FF0102' }}>
                        <Query
                          query={GET_NOTIFICATIONS_FOR_NAVBAR}
                          variables={{ ownerId: parseInt(ownerId), ownerType: "DealerPerson" }}
                        >
                          {props => <NotificationsCount ownerId={ownerId} ownerType={"DealerPerson"} {...props} />}
                        </Query> new
                        </span>
                      )
                    </span>
                  </ApolloProvider>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </InputGroup>
          <Nav className="mr-auto">
            <Nav.Item>
              <LinkContainer to="/cars" style={navItem}>
                <Nav.Link>
                  <FontAwesomeIcon icon={faCar} />
                  <span style={navText}>{dealerName}</span>
                </Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item>
              <LinkContainer to="/cars" style={navItem}>
                <Nav.Link>
                  <FontAwesomeIcon icon={faCars} />
                  <span style={navText}>CARS</span>
                </Nav.Link>
              </LinkContainer>
            </Nav.Item>
            {auth.hasPolicy('Quote', 'create') &&
              <Nav.Item>
                <LinkContainer to="/payments" style={navItem}>
                  <Nav.Link>
                    <FontAwesomeIcon icon={faMoneyCheckEditAlt} />
                    <span style={navText}>MAKE A PAYMENT</span>
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
            }
            {auth.hasPolicy('Quote', 'read') &&
              <Nav.Item>
                <LinkContainer to="/receipts" style={navItem}>
                  <Nav.Link>
                    <FontAwesomeIcon icon={faReceipt} />
                    <span style={navText}>RECEIPTS</span>
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
            }
            {auth.hasPolicy('Report', 'read') && (
              <Nav.Item>
                <ReportingLink
                  dealer={data.currentDealer}
                  setExpanded={setExpanded}
                />
              </Nav.Item>
            )}
          </Nav>
        </Navbar.Collapse>
        <Navbar className="noBoxShadow">
          <ElasticsearchBox dealerId={dealerId} />
          <Dropdown as={NavItem}>
            <Dropdown.Toggle as={NavLink} style={navItem}>
              <FontAwesomeIcon icon={faUserCircle} className="fa-2x" style={{ color: Colors.xlOrange, marginLeft: '20px' }} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Nav.Link
                href="/profile/edit"
                component={Dropdown.Item}
                style={{ color: Colors.xlGreen }}
              >
                Update Profile
                </Nav.Link>
              <Nav.Link
                href="/profile/change-password"
                component={Dropdown.Item}
                style={{ color: Colors.xlGreen }}
              >
                Edit Password
              </Nav.Link>
              <Nav.Link
                href="/profile/update-mobile"
                component={Dropdown.Item}
                style={{ color: Colors.xlGreen }}
              >
                Update Mobile
              </Nav.Link>
              {auth.user && auth.user.multiplePeople && (
                <Nav.Link
                  href="#"
                  onClick={() => {
                    localStorage.removeItem(DEALER_PERSON_ID);
                    window.location.assign('/');
                  }}
                  component={Dropdown.Item}
                  style={{ color: Colors.xlGreen }}
                >
                  Change Dealer
                </Nav.Link>
              )}
              <Nav.Link
                href="/logout"
                component={Dropdown.Item}
                style={{ color: Colors.xlGreen }}
              >
                Logout
                </Nav.Link>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar>
      </Navbar>
      <div style={bottomBorder}></div>
      <RenderRoutes />
    </>
  );
}

export default TopNavigation;
