import { gql } from '@apollo/client';

export const UPDATE_CURRENT_USER = gql`
  mutation UpdateDealerPerson(
    $id: ID!
    $firstName: String
    $lastName: String
    $notificationsEmail: Boolean
    $notificationsSms: Boolean
  ) {
    updateDealerPerson(
      id: $id
      firstName: $firstName
      lastName: $lastName
      notificationsEmail: $notificationsEmail
      notificationsSms: $notificationsSms
    ) {
      dealerPerson {
        id
      }
    }
  }
`;
