import React, { useState, useRef, useEffect} from 'react';
import { useParams, Redirect, useRouteMatch } from 'react-router-dom';
import { Spacing } from '../../styles';
import { Container, Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Image, InfoTable, labels, getItems, setValues, getSourceQuery } from '../Cars/functions';
import * as Buttons from '../../elements/buttons';
import DataState from '../DataState';
import { useQuery } from '@apollo/client';
import { DEALER_QUOTES } from '../../queries/viewQueries';
import * as Colors from '../../styles/colors';
import { setAlertData } from '../../utils/setAlertData';
import { useAlert } from '../../hooks/useAlert';
import DataTable from '../../elements/DataTable';
import * as StringHelper from '../../utils/stringHelpers';
import * as DateHelper from '../../utils/dateHelpers';
import { AUTH_TOKEN } from '../../constants';
import secrets from '../../secrets';
import { Dots } from 'react-activity';
import Modal from "react-bootstrap/Modal";
import 'react-activity/dist/react-activity.css';

let source;
let query;

const Receipts = (props) => {
  const [showAlert, displayAlert] = useAlert();
  const [alertType, setAlertType] = useState();
  const route = useRouteMatch();
  const [redirect, setRedirect] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const { loading, error, data } = useQuery(DEALER_QUOTES)

  if (loading && !data) return <DataState.Loading />
  if (error) return <DataState.Error error={error} />;

  const quoteColumns = [
    {
      title: 'Payment Date'
    },
    {
      title: 'Payment Type'
    },
    {
      title: 'Payment Amount'
    },
    {
      title: 'Cars'
    },
    {
      title: 'Dealer Fees'
    },
    {
      title: ''
    }
  ];

  const handlePrint = (quoteId) => {
    setFileLoading(true);
    const axios = require('axios');
    const FileDownload = require('js-file-download');
    const token = localStorage.getItem(AUTH_TOKEN);
    axios({
      method: 'get',
      url: `${secrets.api.baseUri}/print-receipt`,
      responseType: 'blob',
      headers:  {
        'Content-Type' : 'application/json',
        'Accept' : 'application/pdf',
        'Authorization' : `${token}`,
        'JWT_AUD' : 'react'
      },
      params: {
        'quote-id': quoteId
      }
    })
    .then(res => {
      var date = new Date();
      var timestamp = date.getTime();
      setFileLoading(false);
      FileDownload(res.data, `Payment_Receipt_${timestamp}.pdf`);
    })
  }

  $(document).unbind().on('click', '.receipt-print', function (event) {
    event.preventDefault();
    let quoteId = event.target.dataset.id;
    quoteId = parseInt(quoteId);
    handlePrint(quoteId);
  })

  const dataSet = data.currentDealer.quotes.map((quote) => {
    let cars = '';
    quote.quoteCars.forEach((car) => {
      let flooredCar = car.flooredCar;
      let feeTotal = 0.0;
      car.quoteFees.forEach((fee) => {
        feeTotal += fee.fee.amount;
      });
      cars += `${flooredCar.vin} ${flooredCar.year} ${flooredCar.make}
        ${flooredCar.model} ${flooredCar.color}`
      cars += `<br>`;
    });
    let dealerFees = '';
    quote.quoteFees.forEach((fee) => {
      dealerFees += `${fee.fee.name} ${StringHelper.currency(fee.fee.amount)}`
    });
    return [
      quote.bankDate,
      quote.txns[0].type,
      StringHelper.currency(parseFloat(quote.paymentAmount) +
        parseFloat(quote.txns[0].overageForReceipt)),
      cars,
      dealerFees,
      `<a href='' class='receipt-print' data-id='${quote.id}')>Print Receipt</a>`
    ]
  })

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 30px',
  }

  const hrStyle = {
    width: '100%',
  }

  if (fileLoading) {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        show={fileLoading}
      >
        <div style={{display: 'flex', flex: 1, justifyContent: 'space-around',
          alignItems: 'center', paddingTop: '20px', paddingBottom: '20px'}}>
          <Dots size={50} color={'#2b6448'} />
        </div>
      </Modal>
    );
  }

  return (
    <>
      <div className="container" style={containerStyle}>
        <DataTable
          dataSet={dataSet}
          columns={quoteColumns}
          pageTitle={`Payments`}
          unhideable={true}
          uncreatable={true}
          tableId='#receipts'
        />
      </div>
    </>
  );
};

export default Receipts;
