import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import * as BasicForm from '../../elements/forms';
import { Spacing } from '../../styles';
import * as Buttons from '../../elements/buttons';
import { useFormikContext } from 'formik';

let modalBodyStyle = {
  height: '100%',
  paddingBottom: '20px'
};

let labelStyle = {
  marginTop: '20px'
};

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
  alignItems: 'baseline'
}

export const ProcessNewFund = ({ showModal, setShowModal, handleSubmit }) => {
  const { setFieldValue, isValid, dirty, values, errors } = useFormikContext();
  const [formSubmitting, setFormSubmitting] = useState();

  const handleClose = () => setShowModal(false);

  return (
    <>
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
        onHide={() => setShowModal(false)}
        show={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Request Use Of Funds
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalBodyStyle}>
          <div style={divStyle}>
            <BasicForm.StyledLabel>Select Balance:</BasicForm.StyledLabel>
            <BasicForm.Radio
              name="account"
              type="radio"
              label="Reserve"
              value="Reserve Balance"
            />
            <BasicForm.Radio
              name="account"
              type="radio"
              label="On Account"
              value="On Account Balance"
            />
          </div>
          <BasicForm.TextInput
            name="amount"
            type="number"
            label="Amount"
            labelStyle={labelStyle}
          />
          <BasicForm.TextInput
            name="memo"
            type="text"
            label="Details Of Use"
            labelStyle={labelStyle}
          />
          <div style={Spacing.buttonRow}>
            <Buttons.ModalCancel type='cancel' text='Cancel' handleClose={handleClose} />
            <Buttons.Standard
              type='submit'
              text='Submit'
              onClick={() => handleSubmit(values)}
              disabled={!isValid || formSubmitting || !dirty}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
