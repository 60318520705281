import * as Colors from '../../styles/colors';

export const page = {
  paddingBottom: '50px'
}

export const containerStyle = {
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '600px',
  height: '900px'
}

export const orange = {
  color: Colors.xlOrange,
  paddingTop: "10px",
  paddingLeft: "10px",
  textAlign: 'center'
}

export const green = {
  color: Colors.xlGreen,
  textAlign: 'center',
  paddingBottom: '20px'
}

export const tracking = {
  fontWeight: 'bold',
  color: Colors.xlOrange,
  fontSize: '20px'
}
